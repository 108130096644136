import React, { useState } from 'react';
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,                                                               
  Typography,
  Button,
} from '@mui/material';

const CommissionTable = ({ data }) => {
  const [page, setPage] = useState(1); // Start page from 1 for display purposes
  const rowsPerPage = 7;
  const pageCount = Math.ceil(data.length / rowsPerPage); // Calculate total number of pages

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "TableData.xlsx");
  };

  const cellStyle = {
    padding: '4px 8px', 
    fontSize: '0.875rem', 
    border: '1px solid #ddd', 
  };

  const headerCellStyle = {
    backgroundColor: '#1A3751',
    color: 'white',
    fontWeight: 'bold',
    padding: '6px 8px', 
    fontSize: '0.9rem', 
    border: '0.5px solid #ddd', 
  };

  return (
    <Box display="flex">
      {/* Table and Pagination Section */}
        <Box flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h7" fontWeight="bold">
            Commission Missing Trail
          </Typography>
          
          <button
            onClick={downloadExcel}
            style={{
              backgroundColor: "#fff",
              color: "#284867",
              padding: "6px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease",
            }}
          >
            View Report
          </button>
        </Box>

        <TableContainer component={Paper} style={{ maxHeight: rowsPerPage * 40 * 7 }}>
          <Table stickyHeader aria-label="commission table">
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Reference Id</TableCell>
                <TableCell style={{ ...headerCellStyle, width: '250px' }}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Missing Month</TableCell>
                <TableCell style={headerCellStyle}>Date Settled</TableCell>
                <TableCell style={headerCellStyle}>Lender</TableCell>
                <TableCell style={headerCellStyle}>Current Balance</TableCell>
                <TableCell style={headerCellStyle}>Comm. Run Date</TableCell>
                <TableCell style={headerCellStyle}>Allocated Broker </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={cellStyle}>{item.commissionRefId}</TableCell>
                    <TableCell style={{ ...cellStyle, width: '250px' }}>{item.clientName}</TableCell>
                    <TableCell style={cellStyle}>{item.missingMonth}</TableCell>
                    <TableCell style={cellStyle}>{item.dateSettled}</TableCell>
                    <TableCell style={cellStyle}>{item.lender}</TableCell>
                    <TableCell style={cellStyle}>{item.currentBalance.toFixed(2)}</TableCell>
                    <TableCell style={cellStyle}>{item.commissionRunDate}</TableCell>
                    <TableCell style={cellStyle}>{item.allocatedBroker}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="flex-end" padding={1}>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            siblingCount={0} 
            boundaryCount={1} 
            showFirstButton
            showLastButton
            size="small" 
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: '0.75rem', 
                minWidth: '15px', 
                height: '15px', 
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CommissionTable;
