import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@mui/material";

const VerticalLollipopGraph = ({ data, yAxisLabel, xAxisLabel }) => {
  const maxLoan = Math.max(...data.map((item) => item.yAxis));
  const yAxisLimit = Math.ceil(maxLoan * 1.5);

  const renderCustomizedLabel = (props) => {
    const { x, width, value } = props;
    const radius = 15;

    return (
      <g>
        <circle cx={x + width / 2} cy={20} r={radius} fill="#284867" />

        <text
          x={x + width / 2}
          y={20}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <ResponsiveContainer minWidth="530px" height={275}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 20, left: 5, bottom: 35 }}
          padding={{ top: 10 }}
          barCategoryGap={15}
          barSize={25}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="xAxis"
            tick={{ fontSize: 10, fontWeight: "bold" }}
            angle={-30}
            textAnchor="end"
            interval={0}
            label={{
              value: xAxisLabel, // X-axis label
              position: "bottom", // Centered below the chart
              dy: 18,
              fontSize: 14,
            }}
          />
          <YAxis
            dataKey="yAxis"
            type="number"
            domain={[0, yAxisLimit]}
            tick={{ fontSize: 14, fontWeight: "bold" }}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "insideLeft",
              dx: 3,
              fontSize: 14,
            }}
            // padding={{ top: 20 }}
          />
          <Tooltip
            formatter={(value) => [`${value} ${yAxisLabel}`]}
            cursor={{ fill: "transparent" }}
          />

          <Bar
            dataKey="yAxis"
            fill="#284867"
            radius={[10, 10, 0, 0]}
            width={"10px"}
            background={{ fill: "#eee" }}
          >
            <LabelList dataKey="yAxis" content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default VerticalLollipopGraph;
