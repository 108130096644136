import React from "react";
import { Modal, Box, Typography, Stack, Button } from "@mui/material";

const OverwriteExcelConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Confirm Overwrite
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          File already uploaded. Are you sure you want to overwrite the data?
        </Typography>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              borderColor: "#284867",
              color: "#284867",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            sx={{
              backgroundColor: "#284867",
              color: "white",
              "&:hover": {
                backgroundColor: "#4e7ba6",
              },
            }}
          >
            Overwrite
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default OverwriteExcelConfirmationModal;
