export const displayDashboardValues = {
  main: "main",
  commission: "commission",
  sales: "sales",
  portfolio: "portfolio",
  opportunity: "opportunity",
};

export const chartOptions = [
  { value: "salesFigure", label: "Sales Figure" },
  { value: "postUnconditional", label: "Post Unconditional" },
];

export const activeClientBaseUrl = "/active-client-data/commission";


export const dashboardColors = [
  "#0D3B66",  
  "#2A9D8F",  
  "#A8DADC",  
  "#547AA5",  
  "#9A8C98",  
];

export const dashboardColorsContrast = [
  "#102A43",  
  "#F4A261",  
  "#2A9D8F", 
  "#E9C46A",  
  "#F1FAEE",  
];



export const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  row: {
    flex: 1,
    padding: "10px",
    boxSizing: "border-box",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  titleAndDateControls: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    color: "#284867",
    fontWeight: 600,
    marginBottom: "10px",
  },
  title: {
    marginRight: "40px",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  },
  dateLabel: {
    marginRight: "5px",
    color: "#284867",
    fontWeight: 600,
  },
  dropdownContainer: {
    width: "200px",
    fontSize: "14px",
    color: "#284867",
    marginLeft: "10px",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: "600",
    color: "#284867",
  },
  viewReportButton: {
    backgroundColor: "#fff",
    color: "#284867",
    padding: "6px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
    transition: "boxShadow 0.3s ease",
    marginLeft: "10px",
  },
  loadButton: {
    backgroundColor: "#284867",
    color: "#fff",
    padding: "6px 10px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "boxShadow 0.3s ease",
    width: "auto",
    height: "28px",
  },
  datePickerCustomInput: {
    minWidth: "100px",
    width: "auto",
    padding: "5px 10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#fff",
    color: "#284867",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "14px",
  },
};
