import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { hostUrl } from "modules";

const OverDueTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const [visiblePages, setVisiblePages] = useState([]);

  function convertToSimpleDate(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formattedData = data?.map((item) => {
    const lastStageChange = item.stageChanges[item.stageChanges.length - 1];
    return {
      "Broker Name": item.broker_name,
      "Application ID": item.id,
      "Task Description": item.currentTaskDescription,
      "Task Due Date": convertToSimpleDate(item.currentTaskDueDate),
      "Deal Loan Amount": item.loanAmount,
      "Stage Name": lastStageChange?.stageName || "",
      Pipeline: lastStageChange?.pipeline || "",
      "Deal Type": item.dealType,
      Lender: item.lenderName,
      Processor: item.processor_name,
      "Client Name": item.user_name,
      "Client SMS Verified Y/N": item.user_isMobileVerified ? "Yes" : "No",
    };
  });

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "TableData.xlsx");
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = new URL(hostUrl + '/dueApplications');
      setLoading(true);
      try {
        const response = await fetch(fetchUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const visiblePagesArray = Array.from(
      { length: totalPages },
      (_, i) => i + 1
    ).slice(
      Math.max(0, currentPage - 3),
      Math.min(totalPages, currentPage + 2)
    );
    setVisiblePages(visiblePagesArray);
  }, [data, currentPage]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        minHeight: "416px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            color: "#284867",
            fontWeight: 600,
          }}
        >
          Overdue Work Items/ Follow Ups
        </div>
        <button
          onClick={downloadExcel}
          style={{
            backgroundColor: "#fff",
            color: "#284867",
            padding: "6px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
            transition: "boxShadow 0.3s ease",
          }}
        >
          View Report
        </button>
      </div>
      {!loading ? (
        <>
          <div style={{ overflow: "auto", height: "300px" }}>
            <table
              style={{
                whiteSpace: "nowrap",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#284867",
                  color: "#fff",
                  fontWeight: 600,
                }}
              >
                <tr>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Broker Name
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Task Due Date
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Task Description
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Application ID
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Application Loan Amount
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Stage Name
                  </th>
                  <th
                    style={{
                      border: "2px solid #213d58",
                      padding: "8px",
                      height: "25px",
                    }}
                  >
                    Pipeline
                  </th>
                </tr>
              </thead>

              <tbody style={{ fontSize: "14px" }}>
                {currentItems?.map((item, index) => {
                  const lastStageChange =
                    item.stageChanges[item.stageChanges.length - 1];
                  return (
                    <tr key={index}>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {item["broker_name"]}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {convertToSimpleDate(item["currentTaskDueDate"])}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {item["currentTaskDescription"]}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {item["id"]}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {item["loanAmount"]}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {lastStageChange?.stageName || ""}
                      </td>
                      <td
                        style={{ border: "2px solid #213d58", padding: "8px" }}
                      >
                        {lastStageChange?.pipeline || ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo;
            </button>
            {visiblePages.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  fontWeight: page === currentPage ? "bold" : "normal",
                  margin: "0 5px",
                  background: "#fff",
                  color: page === currentPage ? "#213d58" : "gray",
                  padding: "2px",
                  border: "none",
                }}
              >
                {page}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "600",
            color: "#284867",
          }}
        >
          Loading...
        </div>
      )}
    </div>
  );
};

export default OverDueTable;
