import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { hostUrl } from "modules";
import { Loader } from "components/Loader";

const InfinityClients = () => {
  const [search, setSearch] = useState("");
  const [searchField, setSearchField] = useState("applicantName");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [error, setError] = useState(null);
  const [syncStatus, setSyncStatus] = useState([]);
  const history = useHistory();

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchFieldChange = (event) => {
    setSearchField(event.target.value);
  };

  const filteredData = data.filter((item) => {
    if (searchField === "brokerName") {
      return item?.brokerName?.toLowerCase().includes(search.toLowerCase());
    } else if (searchField === "applicantName") {
      return item?.account_name?.toLowerCase().includes(search.toLowerCase());
    }
    return false;
  });
  const columns = [
    "Broker ID",
    "Broker Name",
    "Account ID",
    "Account Name",
    "External Reference",
    "Reference Name",
    "Is Lead",
  ];
  const fetchData = async () => {
    setSyncLoading(true);
    try {
      const response = await fetch(`${hostUrl}/sync-infynity`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setSyncLoading(false);
    }
  };

  const syncData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${hostUrl}/clients-Infynity`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    history.push(
      `/infinity-clients/view?accountId=${row.account_id}&brokerId=${row.brokerId}`,
      {
        account: { accountId: row.account_id },
        applicants: row.applicants,
        broker: { brokerName: row.brokerName, brokerId: row.brokerId },
      }
    );
  };

  const checkSync = async () => {
    try {
      const response = await fetch(
        `${hostUrl}/sync-infynity-logs?status=InProgress`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setSyncStatus(result);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    syncData();
    checkSync();
  }, []);

  return (
    <main>
      <Box>
        <div
          style={{
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            color: "#284867",
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Filter
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: "20px",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Select
              size="small"
              value={searchField}
              onChange={handleSearchFieldChange}
              sx={{ fontSize: "14px" }}
            >
              <MenuItem value="applicantName" sx={{ fontSize: "14px" }}>
                Search with Account Name
              </MenuItem>
              <MenuItem value="brokerName" sx={{ fontSize: "14px" }}>
                Search with Broker Name
              </MenuItem>
            </Select>
            <TextField
              label={`Search with ${
                searchField === "brokerName" ? "Broker Name" : "Account Name"
              }`}
              fontSize="14px"
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
              size="small"
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
          </div>
          <Button
            disabled={syncLoading || syncStatus.length !== 0}
            variant="contained"
            color="primary"
            size="small"
            sx={{
              minWidth: "100px",
              textTransform: "capitalize",
              background: "#284867",
              fontSize: "14px",
            }}
            onClick={fetchData}
          >
            {syncLoading || syncStatus.length !== 0
              ? "Syncing ..."
              : "Sync Data"}
          </Button>
        </div>

        {loading ? (
          <div style={{ top: "30vh", position: "relative" }}>
            <Loader />
          </div>
        ) : error && data.length === 0 ? (
          <div>Error: {error}</div>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "calc(100vh - 244px)" }}
          >
            <Table stickyHeader>
              <TableHead sx={{ whiteSpace: "nowrap" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        height: "30px",
                        padding: "10px",
                        color: "#575e6c",
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow
                    onClick={() => handleRowClick(row)}
                    key={`${row.brokerId}-${index}`}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell>{row.brokerId}</TableCell>
                    <TableCell>{row.brokerName}</TableCell>
                    <TableCell>{row.account_id}</TableCell>
                    <TableCell>{row.account_name}</TableCell>
                    <TableCell>{row.external_reference}</TableCell>
                    <TableCell>{row.referrer_name}</TableCell>
                    <TableCell>{row.is_lead ? "Yes" : "No"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </main>
  );
};

export default InfinityClients;
