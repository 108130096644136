import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LollipopChart = ({
  data,
  yAxisLabel,
  xAxisLabel,
  reverseYAxis = true,
  gap = 15,
  graphMargin = { top: 20, right: 30, bottom: 30, left: 81 },
  lineColor = "#284867", 
  progressBarColor = "#284867", // default color for progress bars
  backgroundBarColor = "#d3d3d3",
  circleColor = "#284867", // default circle color
  textColor = "white", // default text color
}) => {
  const svgRef = useRef();

  const transformData = (data) => {
    if (!data) return;
    return Object.keys(data)?.map((key) => {
      return { name: key, value: data[key] };
    });
  };

  useEffect(() => {
    if (!data || !Object.keys(data).length) return;

    const chartData = transformData(data);
    const svg = d3.select(svgRef.current);
    const width = 540;
    const height = 300;
    const margin = graphMargin;

    svg.selectAll("*").remove();

    const maxValue = d3.max(chartData, (d) => d.value) + 2;

    const x = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleBand()
      .domain(
        reverseYAxis
          ? chartData.map((d) => d.name).reverse()
          : chartData.map((d) => d.name)
      )
      .range([margin.top, height - margin.bottom])
      .padding(0.4);

    const yAxis = d3.axisLeft(y).tickSize(0);
    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll("text")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .attr("dx", "-5px");

    svg.selectAll(".domain").remove();

    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        `translate(${margin.left / 6},${height / 2})rotate(-90)`
      )
      .attr("font-size", "14px")
      .attr("fill", "#808080")
      .attr("font-weight", "bold")
      .text(yAxisLabel);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickSize(0).tickFormat(""))
      .selectAll("path")
      .remove();

    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr(
        "transform",
        `translate(${(width - margin.left - margin.right) / 2 + margin.left},${
          height - 5
        })`
      )
      .attr("font-size", "14px")
      .attr("fill", "#808080")
      .attr("font-weight", "bold")
      .text(xAxisLabel);

    svg
      .selectAll("line.background")
      .data(chartData)
      .enter()
      .append("line")
      .attr("x1", x(0))
      .attr("x2", x(maxValue))
      .attr("y1", (d) => y(d.name) + y.bandwidth() / 2)
      .attr("y2", (d) => y(d.name) + y.bandwidth() / 2)
      .attr("stroke", backgroundBarColor)
      .attr("stroke-width", 8)
      .attr("stroke-linecap", "round");

    svg
      .selectAll("line.lollipop")
      .data(chartData)
      .enter()
      .append("line")
      .attr("x1", (d) => x(0) + (d.value === 0 ? gap : 0))
      .attr("x2", (d) => x(d.value))
      .attr("y1", (d) => y(d.name) + y.bandwidth() / 2)
      .attr("y2", (d) => y(d.name) + y.bandwidth() / 2)
      .attr("stroke", progressBarColor) // Use progressBarColor prop
      .attr("stroke-width", 8)
      .attr("stroke-linecap", "round");

    svg
      .selectAll("circle")
      .data(chartData)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.value) + (d.value === 0 ? gap : 0))
      .attr("cy", (d) => y(d.name) + y.bandwidth() / 2)
      .attr("r", 14)
      .attr("fill", circleColor); 

    svg
      .selectAll("text.loan-value")
      .data(chartData)
      .enter()
      .append("text")
      .attr("x", (d) => x(d.value) + (d.value === 0 ? gap : 0))
      .attr("y", (d) => y(d.name) + y.bandwidth() / 2 + 5)
      .attr("text-anchor", "middle")
      .attr("fill", textColor) // Use textColor prop
      .attr("font-size", "12px")
      .text((d) => d.value);

    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, gap, reverseYAxis, lineColor, circleColor, textColor, progressBarColor]);

  if (!data || !Object.keys(data).length) return <div>No data</div>;

  return <svg ref={svgRef} width={540} height={300} />;
};

export default LollipopChart;
