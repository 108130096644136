import React, { useState, useCallback, useEffect } from "react";
import Card from "components/Styled/Card";
import { Row as BaseRow, Column as BaseColumn } from "components/Styled/Layout";
import BackButton from "components/Common/BackButton";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { hostUrl } from "modules";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Spinner from "../../img/icons/spinner.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { Bold } from "shared/PDF/Shared";

const TabBar = styled.div`
  height: 60px;
  width: 100%;
  overflow: hidden;
`;

const SubtabBar = styled.div`
  width: 100%;
  margin-bottom: -1px;
  margin-top: 24px;
  overflow: visible;
`;

const SubtabBase = styled.div`
  min-width: 188px;
  padding: 18px 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #ececec;
  border: 1px solid #d8dce7;
  color: #4f4f4f;
  position: relative;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background-color: #fff;
      border-bottom: none;
      color: #101922;
      cursor: default;

      &:after {
        content: "";
        background-color: #fff;
        /*   */
        height: 5px;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        z-index: 1000;
      }

      &:hover {
        border-bottom: none;
      }
    `}

  &:hover {
    background-color: #fff;
  }
`;

const TabBase = styled.div`
  width: 125px;
  height: 37px;
  display: inline-block;
  margin: 23px 0 0 40px;
  text-align: center;
  font-size: 14px;
  color: #727c8f;
  margin-bottom: 40px;
  cursor: pointer;
  ${(p) =>
    p.current &&
    css`
      color: #101922;
      border-bottom: solid 2px #2291ff;
    `}
`;

const Subtab = ({ page, children, currentSubtab, setSubtab }) => {
  return (
    <SubtabBase onClick={() => setSubtab(page)} active={currentSubtab === page}>
      {children}
    </SubtabBase>
  );
};

const Tab = ({ page, children, currentTab, setTab }) => (
  <TabBase onClick={() => setTab(page)} current={currentTab === page}>
    {children}
  </TabBase>
);

const subtabPage = {
  DEFAULT: 0,
  INDIVIDUAL: 0,
  COMPANY: 1,
  TRUST: 2,
};

const subtabFinancials = {
  DEFAULT: 0,
  INCOMES: 0,
  LIABILITIES: 1,
  ASSETS: 2,
  EXPENSES: 3,
};

const tabPage = {
  DEFAULT: 0,
  APPLICANTS: 0,
  LOANSPLITS: 1,
  FINANCIALS: 2,
};

const columns = [
  "Broker ID",
  "Broker Name",
  "Applicant ID",
  "Applicant Name",
  "Applicant Type",
  "",
];

const loanColumns = [
  "Loan",
  "Application",
  "Facility Amount",
  "Lender",
  "Status",
  "Purpose",
  "Settled Date",
];

const splitColumns = [
  "Split",
  "Reference Number",
  "Amount",
  "Term",
  "Repayment Type",
  "Lock Rate",
];

const incomeColumns = [
  "income id",
  "individual id",
  "company id",
  "trust id",
  "type",
  "employment id",
  "frequency",
  "amount",
  "gross",
];

const liabilitiesColumns = [
  "liability id",
  "assest id",
  "liability type",
  "address id",
  "lender id",
  "lender other",
  "unpaid balance",
  "scheduled balance",
  "description",
  "clearing from this loan",
  "interest rate",
  "monthly repayment",
  "owners",
];

const assetsColumns = [
  "asset id",
  "type",
  "address id",
  "original value",
  "current value",
  "value basis",
  "description",
  "use as security",
  "is being purchased",
  "currently owned",
  "institution name",
  "account number",
  "account bsb",
  "account name",
  "account type",
  "vehicle make",
  "vehicle year of manufacture",
  "purchase date",
  "owners",
];

const expensesColumns = [
  "expense id",
  "type",
  "frequency",
  "post settlement",
  "amount",
  "description",
  "owners",
];

const ViewInfynityClients = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(undefined);
  const { applicants, broker, account } = location.state || {}; // Access the data passed

  const [currentTab, setCurrentTab] = useState(tabPage.DEFAULT);
  const [currentSubtab, setCurrentSubtab] = useState(subtabPage.DEFAULT);

  const [currentSubtabFinancials, setCurrentSubtabFinancials] = useState(
    subtabFinancials.DEFAULT
  );

  const [getByIdData, setGetByIdData] = useState();
  const [getEmploymentByIdData, setGetEmploymentByIdData] = useState([]);
  const [getAddressByIdData, setGetAddressByIdData] = useState([]);
  const [loanData, setLoanData] = useState();
  const [splitAllData, setsplitAllData] = useState();
  const [splitData, setSplitData] = useState();

  const [showCard, setShowCard] = useState({
    details: false,
    address: false,
    employment: false,
  });

  const [financialsAPIData, setFinancialsAPIData] = useState({
    assets: [],
    liabilities: [],
    expenses: [],
    incomes: [],
  });

  const setTab = (tab) => {
    setCurrentTab(tab);
    setCurrentSubtab(subtabPage.DEFAULT);
    setGetByIdData();
    setGetEmploymentByIdData([]);
    setGetAddressByIdData([]);
  };

  const setSubtab = (tab) => {
    setCurrentSubtab(tab);
    setGetByIdData();
    setGetEmploymentByIdData([]);
    setGetAddressByIdData([]);
  };

  const setSubtabFinancials = (tab) => {
    setCurrentSubtabFinancials(tab);
  };

  const replaceUnderscoresWithSpaces = (str) => {
    return str.replace(/_/g, " ");
  };

  const getById = async (id) => {
    if (!id) return;

    setLoading(id);

    switch (currentSubtab) {
      case subtabPage.INDIVIDUAL:
        try {
          const [firstResponse, secondResponse, thirdResponse] =
            await Promise.all([
              fetch(`${hostUrl}/individual-infynity/${id}`),
              fetch(`${hostUrl}/employment-infynity?individual_id=${id}`),
              fetch(
                `${hostUrl}/address-infynity?entity_id=${id}&entity_type=individual`
              ),
            ]);

          if (!firstResponse.ok || !secondResponse.ok || !thirdResponse.ok) {
            console.error("API PROBLEM");
            return;
          }

          const [firstResult, secondResult, thirdResult] = await Promise.all([
            firstResponse.json(),
            secondResponse.json(),
            thirdResponse.json(),
          ]);

          setGetByIdData(firstResult);
          setGetEmploymentByIdData(secondResult);
          setGetAddressByIdData(thirdResult);
        } finally {
          setLoading(undefined);
        }
        break;

      case subtabPage.COMPANY:
        try {
          const [firstResponse, secondResponse] = await Promise.all([
            fetch(`${hostUrl}/company-infynity/${id}`),
            fetch(
              `${hostUrl}/address-infynity?entity_id=${id}&entity_type=company`
            ),
          ]);

          if (!firstResponse.ok || !secondResponse.ok) {
            console.error("API PROBLEM");
            return;
          }

          const [firstResult, secondResult] = await Promise.all([
            firstResponse.json(),
            secondResponse.json(),
          ]);

          setGetByIdData(firstResult);
          setGetAddressByIdData(secondResult);
        } finally {
          setLoading(undefined);
        }
        break;

      case subtabPage.TRUST:
        try {
          const [firstResponse, secondResponse] = await Promise.all([
            fetch(`${hostUrl}/trust-infynity/${id}`),
            fetch(
              `${hostUrl}/address-infynity?entity_id=${id}&entity_type=trust`
            ),
          ]);

          if (!firstResponse.ok || !secondResponse.ok) {
            console.error("API PROBLEM");
            return;
          }

          const [firstResult, secondResult] = await Promise.all([
            firstResponse.json(),
            secondResponse.json(),
          ]);

          setGetByIdData(firstResult);
          setGetAddressByIdData(secondResult);
        } finally {
          setLoading(undefined);
        }
        break;
    }
  };

  const getFinancialsData = async () => {
    try {
      const [firstResponse, secondResponse, thirdResponse, fourthResponse] =
        await Promise.all([
          fetch(`${hostUrl}/income-infynity?account_id=${account.accountId}`),
          fetch(
            `${hostUrl}/liability-infynity?account_id=${account.accountId}`
          ),
          fetch(`${hostUrl}/asset-infynity?account_id=${account.accountId}`),
          fetch(`${hostUrl}/expense-infynity?account_id=${account.accountId}`),
        ]);

      if (
        !firstResponse.ok ||
        !secondResponse.ok ||
        !thirdResponse.ok ||
        !fourthResponse.ok
      ) {
        console.error("API PROBLEM");
        return;
      }

      const [firstResult, secondResult, thirdResult, fourthResult] =
        await Promise.all([
          firstResponse.json(),
          secondResponse.json(),
          thirdResponse.json(),
          fourthResponse.json(),
        ]);

      setFinancialsAPIData((prevState) => ({
        ...prevState,
        incomes: firstResult,
        liabilities: secondResult,
        assets: thirdResult,
        expenses: fourthResult,
      }));
    } finally {
      setLoading(undefined);
    }
  };

  const tabProps = useCallback(
    (page) => ({
      page,
      currentTab,
      currentSubtab,
      setSubtab,
      setTab,
    }),
    [currentTab, currentSubtab]
  );

  const financialSubtabProps = useCallback(
    (page) => ({
      page,
      currentSubtab: currentSubtabFinancials,
      setSubtab: setSubtabFinancials,
    }),
    [currentSubtabFinancials]
  );

  const getLoans = async () => {
    try {
      const response = await fetch(
        `${hostUrl}/loans-infynity?account_id=${account.accountId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setLoanData(result);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLoanRowClick = async (id) => {
    setSplitData();
    setsplitAllData();
    try {
      const response = await fetch(
        `${hostUrl}/splits-infynity?broker_id=${broker.brokerId}&account_id=${account.accountId}&loan_id=${id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setsplitAllData(result);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSplitRowClick = async (id) => {
    setSplitData();
    try {
      const response = await fetch(`${hostUrl}/splits-infynity/${id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setSplitData(result);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getFinancialsHeaderData = () => {
    switch (currentSubtabFinancials) {
      case subtabFinancials.ASSETS:
        return assetsColumns;

      case subtabFinancials.EXPENSES:
        return expensesColumns;

      case subtabFinancials.INCOMES:
        return incomeColumns;

      case subtabFinancials.LIABILITIES:
        return liabilitiesColumns;

      default:
        return incomeColumns;
    }
  };

  const getValue = (value) => {
    if (typeof value === "boolean") {
      if (value) return "Yes";

      return "No";
    }

    return value;
  };

  useEffect(() => {
    getLoans();
    getFinancialsData();
  }, []);

  return (
    <main className="main client-view" style={{ minWidth: "950px" }}>
      <BaseRow>
        <BaseColumn width="100%">
          <BaseColumn width="200px">
            <BackButton margin="15px 0 0 0" style={{ marginBottom: "20px" }} />
          </BaseColumn>
        </BaseColumn>
      </BaseRow>
      <Card minWidth="800px" padding="0" minHeight="0px">
        <TabBar>
          <Tab {...tabProps(tabPage.APPLICANTS)}>Applicants</Tab>

          <Tab {...tabProps(tabPage.LOANSPLITS)}>Loans & Splits</Tab>
          <Tab {...tabProps(tabPage.FINANCIALS)}>Financials</Tab>
        </TabBar>
      </Card>

      {currentTab === 0 && (
        <>
          <Box mt={1}>
            <SubtabBar>
              <Subtab {...tabProps(subtabPage.INDIVIDUAL)}>Individual</Subtab>
              <Subtab {...tabProps(subtabPage.COMPANY)}>Company</Subtab>
              <Subtab {...tabProps(subtabPage.TRUST)}>Trust</Subtab>
            </SubtabBar>
          </Box>
          <Box
            p={2}
            sx={{
              backgroundColor: "white",
              border: "1px solid #D8DCE7",
              borderRadius: "4px",
            }}
          >
            <Stack direction="row" gap={2}>
              <Box flex={1}>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: "calc(100vh - 244px)" }}
                >
                  <Table stickyHeader>
                    <TableHead sx={{ whiteSpace: "nowrap" }}>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              height: "30px",
                              padding: "10px",
                              color: "#575e6c",
                            }}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applicants?.map(
                        (item) =>
                          item.applicant_type === "Individual" &&
                          currentSubtab === 0 && (
                            <TableRow
                              onClick={() => {
                                getById(item.applicant_id);
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <TableCell>{broker.brokerId}</TableCell>
                              <TableCell>{broker.brokerName}</TableCell>
                              <TableCell>{item.applicant_id}</TableCell>
                              <TableCell>{item.applicant_name}</TableCell>
                              <TableCell>{item.applicant_type}</TableCell>
                              <TableCell align="right">
                                {loading === item.applicant_id ? (
                                  <img src={Spinner} />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}

                      {applicants?.map(
                        (item) =>
                          item.applicant_type === "Company" &&
                          currentSubtab === 1 && (
                            <TableRow
                              onClick={() => {
                                getById(item.applicant_id);
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <TableCell>{broker.brokerId}</TableCell>
                              <TableCell>{broker.brokerName}</TableCell>
                              <TableCell>{item.applicant_id}</TableCell>
                              <TableCell>{item.applicant_name}</TableCell>
                              <TableCell>{item.applicant_type}</TableCell>
                              <TableCell align="right">
                                {loading === item.applicant_id ? (
                                  <img src={Spinner} />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}

                      {applicants?.map(
                        (item) =>
                          item.applicant_type === "Trust" &&
                          currentSubtab === 2 && (
                            <TableRow
                              onClick={() => {
                                getById(item.applicant_id);
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <TableCell>{broker.brokerId}</TableCell>
                              <TableCell>{broker.brokerName}</TableCell>
                              <TableCell>{item.applicant_id}</TableCell>
                              <TableCell>{item.applicant_name}</TableCell>
                              <TableCell>{item.applicant_type}</TableCell>
                              <TableCell align="right">
                                {loading === item.applicant_id ? (
                                  <img src={Spinner} />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box flex={1} width="100%">
                <Card>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxHeight: "450px",
                      overflowY: "auto",
                      paddingRight: 4,
                    }}
                  >
                    <>
                      {/* Personal Detail Section */}
                      {getByIdData && (
                        <ClickableCard
                          text={
                            currentSubtab === subtabPage.COMPANY
                              ? "Company Detail"
                              : "Personal Detail"
                          }
                          onClickHandler={() =>
                            setShowCard((prevState) => ({
                              ...prevState,
                              details: !prevState.details,
                            }))
                          }
                          showState={showCard.details}
                        />
                      )}
                      <Grid container spacing={4}>
                        {showCard.details &&
                          getByIdData &&
                          Object.entries(getByIdData).map(([key, value]) => (
                            <Grid item xs={6} mb={2} key={key}>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#101922",
                                }}
                                gutterBottom
                              >
                                {replaceUnderscoresWithSpaces(key)}
                              </Typography>
                              <TextField
                                fullWidth
                                value={getValue(value)}
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                  sx: {
                                    fontSize: "14px",
                                    border: "1px solid #d8dce7",
                                    borderRadius: "4px",
                                    height: "40px",
                                    padding: "0px 12px",
                                  },
                                }}
                                InputLabelProps={{ shrink: false }}
                              />
                            </Grid>
                          ))}
                      </Grid>

                      {/* Employment Detail Section */}
                      {getEmploymentByIdData.length !== 0 && (
                        <ClickableCard
                          text="Employment Detail"
                          onClickHandler={() =>
                            setShowCard((prevState) => ({
                              ...prevState,
                              employment: !prevState.employment,
                            }))
                          }
                          showState={showCard.employment}
                        />
                      )}
                      <Grid container spacing={4}>
                        {showCard.employment &&
                          getEmploymentByIdData?.map((item, index) =>
                            Object.entries(item).map(([key, value]) => (
                              <Grid
                                item
                                xs={6}
                                mb={2}
                                key={`${key} - ${index}`}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "#101922",
                                  }}
                                  gutterBottom
                                >
                                  {`${replaceUnderscoresWithSpaces(key)} - ${
                                    index + 1
                                  }`}
                                </Typography>
                                <TextField
                                  fullWidth
                                  value={value || ""}
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                    sx: {
                                      fontSize: "14px",
                                      border: "1px solid #d8dce7",
                                      borderRadius: "4px",
                                      height: "40px",
                                      padding: "0px 12px",
                                    },
                                  }}
                                  InputLabelProps={{ shrink: false }}
                                />
                              </Grid>
                            ))
                          )}
                      </Grid>

                      {/* Address Detail Section */}
                      {getAddressByIdData.length !== 0 && (
                        <ClickableCard
                          text="Address Detail"
                          onClickHandler={() =>
                            setShowCard((prevState) => ({
                              ...prevState,
                              address: !prevState.address,
                            }))
                          }
                          showState={showCard.address}
                        />
                      )}
                      <Grid container spacing={4}>
                        {showCard.address &&
                          getAddressByIdData?.map((item, index) =>
                            Object.entries(item).map(([key, value]) => (
                              <Grid item xs={6} mb={2} key={`${key}-${index}`}>
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "#101922",
                                  }}
                                  gutterBottom
                                >
                                  {`${replaceUnderscoresWithSpaces(key)} - ${
                                    index + 1
                                  }`}
                                </Typography>
                                <TextField
                                  fullWidth
                                  value={getValue(value)}
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                    sx: {
                                      fontSize: "14px",
                                      border: "1px solid #d8dce7",
                                      borderRadius: "4px",
                                      height: "40px",
                                      padding: "0px 12px",
                                    },
                                  }}
                                  InputLabelProps={{ shrink: false }}
                                />
                              </Grid>
                            ))
                          )}
                      </Grid>
                    </>
                  </Grid>
                </Card>
              </Box>
            </Stack>
          </Box>
        </>
      )}

      {currentTab === 1 && (
        <Box
          mt={3}
          p={2}
          sx={{
            backgroundColor: "white",
            border: "1px solid #D8DCE7",
            borderRadius: "4px",
          }}
        >
          <Stack direction="column" gap={2}>
            <Box flex={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#101922",
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                Loans
              </Typography>

              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <Table stickyHeader>
                  <TableHead sx={{ whiteSpace: "nowrap" }}>
                    <TableRow>
                      {loanColumns.map((column) => (
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            height: "30px",
                            padding: "10px",
                            color: "#575e6c",
                            fontWeight: "bold",
                          }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanData?.map((item, index) => (
                      <TableRow
                        onClick={() => handleLoanRowClick(item.loan_id)}
                        key={`${item.loan_id}-${index}`}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell>{item.loan_id}</TableCell>
                        <TableCell>{item.application_id}</TableCell>
                        <TableCell>{item.facility_amount}</TableCell>
                        <TableCell>{item.lender}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>{item.purpose}</TableCell>
                        <TableCell>{item.settled_date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box flex={1} mt={2} width="100%">
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#101922",
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                Splits
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "280px",
                  overflowY: "auto",
                }}
              >
                <Table stickyHeader>
                  <TableHead sx={{ whiteSpace: "nowrap" }}>
                    <TableRow>
                      {splitColumns.map((column) => (
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            height: "30px",
                            padding: "10px",
                            color: "#575e6c",
                            fontWeight: "bold",
                          }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {splitAllData?.map((item, index) => (
                      <TableRow
                        onClick={() => handleSplitRowClick(item.split_id)}
                        key={`${item.split_id}-${index}`}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell>{item.split_id}</TableCell>
                        <TableCell>{item.loan_reference_number}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.term}</TableCell>
                        <TableCell>{item.repayment_type}</TableCell>
                        <TableCell>{item.lock_rate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          {splitData && (
            <Box flex={1} mt={3} width="100%">
              <Card>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#101922",
                    fontWeight: "bold",
                    textAlign: "left",
                    mb: 1,
                  }}
                >
                  Split Detail
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    maxHeight: "160px",
                    overflowY: "auto",
                    paddingRight: 4,
                  }}
                >
                  <DisplayField label={"Id"} value={splitData?.split_id} />
                  <DisplayField
                    label={"Application id"}
                    value={splitData?.application_id}
                  />
                  <DisplayField label={"Amount"} value={splitData?.amount} />
                  <DisplayField
                    label={"Product"}
                    value={splitData?.product?.name}
                  />
                  <DisplayField
                    label={"Package"}
                    value={splitData?.product_package?.name}
                  />
                  <DisplayField
                    label={"Rate Variation"}
                    value={splitData?.product_rate_variation?.name}
                  />
                  <DisplayField label={"Term"} value={splitData?.term} />
                  <DisplayField
                    label={"Repayment Type"}
                    value={splitData?.repayment_type}
                  />
                  <DisplayField
                    label={"Repayment Frequency"}
                    value={splitData?.repayment_frequency}
                  />
                  <DisplayField
                    label={"Interest Only Months"}
                    value={splitData?.interest_only_months}
                  />
                  <DisplayField
                    label={"Initial Rate"}
                    value={splitData?.initial_rate}
                  />
                  <DisplayField
                    label={"Initial Rate Override"}
                    value={splitData?.initial_rate_override}
                  />
                  <DisplayField
                    label={"Initial Rate Months"}
                    value={splitData?.initial_rate_months}
                  />
                  <DisplayField
                    label={"Ongoing Rate"}
                    value={splitData?.ongoing_rate}
                  />
                  <DisplayField
                    label={"Ongoing Rate Override"}
                    value={splitData?.ongoing_rate_override}
                  />
                  <DisplayField
                    label={"Lock Rate"}
                    value={splitData?.lock_rate}
                  />
                  <DisplayField label={"Monthly"} value={splitData?.monthly} />
                  <DisplayField
                    label={"Monthly Fee Override"}
                    value={splitData?.monthly_fee_override}
                  />
                  <DisplayField
                    label={"Annual Fee Override"}
                    value={splitData?.annual_fee_override}
                  />
                  <DisplayField
                    label={"Loan Reference Number"}
                    value={splitData?.loan_reference_number}
                  />
                </Grid>
              </Card>
            </Box>
          )}
        </Box>
      )}

      {currentTab === 2 && (
        <>
          <Box mt={1}>
            <SubtabBar>
              <Subtab {...financialSubtabProps(subtabFinancials.INCOMES)}>
                Incomes
              </Subtab>
              <Subtab {...financialSubtabProps(subtabFinancials.LIABILITIES)}>
                Liabilities
              </Subtab>
              <Subtab {...financialSubtabProps(subtabFinancials.ASSETS)}>
                Assets
              </Subtab>
              <Subtab {...financialSubtabProps(subtabFinancials.EXPENSES)}>
                Expenses
              </Subtab>
            </SubtabBar>
          </Box>
          <Box
            p={2}
            sx={{
              backgroundColor: "white",
              border: "1px solid #D8DCE7",
              borderRadius: "4px",
            }}
          >
            <Stack direction="row" gap={2}>
              <Box flex={1}>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxHeight: "60vh",
                    width: "100%",
                    maxWidth: "80vw",
                    overflow: "auto",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead sx={{ whiteSpace: "nowrap" }}>
                      <TableRow>
                        {getFinancialsHeaderData().map((column) => (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              height: "30px",
                              padding: "10px",
                              color: "#575e6c",
                            }}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentSubtabFinancials === 0 &&
                        financialsAPIData.incomes.map((item) => (
                          <TableRow>
                            <TableCell>{item.income_id}</TableCell>
                            <TableCell>{item.individual_id}</TableCell>
                            <TableCell>{item.company}</TableCell>
                            <TableCell>{item.trust}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.employment_id}</TableCell>
                            <TableCell>{item.frequency}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>
                              {item.is_gross === "1" ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        ))}

                      {currentSubtabFinancials === 1 &&
                        financialsAPIData.liabilities.map((item) => (
                          <TableRow>
                            <TableCell>{item.liability_id}</TableCell>
                            <TableCell>{item.asset}</TableCell>
                            <TableCell>{item.liability_type}</TableCell>
                            <TableCell>{item.address_id}</TableCell>
                            <TableCell>{item.lender_id}</TableCell>
                            <TableCell>{item.lender_other}</TableCell>
                            <TableCell>{item.unpaid_balance}</TableCell>
                            <TableCell>{item.scheduled_balance}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              {item.clearing_from_this_loan}
                            </TableCell>
                            <TableCell>{item.interest_rate}</TableCell>
                            <TableCell>{item.monthly_repayment}</TableCell>
                            <TableCell>
                              {item.owners
                                .map((owner) => owner.individual_id)
                                .join(", ")}
                            </TableCell>
                          </TableRow>
                        ))}

                      {currentSubtabFinancials === 2 &&
                        financialsAPIData.assets.map((item) => (
                          <TableRow>
                            <TableCell>{item.asset_id}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.address_id}</TableCell>
                            <TableCell>{item.original_value}</TableCell>
                            <TableCell>{item.current_value}</TableCell>
                            <TableCell>{item.value_basis}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              {item.use_as_security === "1" ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {item.is_being_purchased === "1" ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {item.currently_owned === "1" ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>{item.institution_name}</TableCell>
                            <TableCell>{item.account_number}</TableCell>
                            <TableCell>{item.account_bsb}</TableCell>
                            <TableCell>{item.account_name}</TableCell>
                            <TableCell>{item.account_type}</TableCell>
                            <TableCell>{item.vehicle_make}</TableCell>
                            <TableCell>
                              {item.vehicle_year_of_manufacture}
                            </TableCell>
                            <TableCell>{item.purchase_date}</TableCell>
                            <TableCell>
                              {item.owners
                                .map((owner) => owner.individual_id)
                                .join(", ")}
                            </TableCell>
                          </TableRow>
                        ))}

                      {currentSubtabFinancials === 3 &&
                        financialsAPIData.expenses.map((item) => (
                          <TableRow>
                            <TableCell>{item.expense_id}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.frequency}</TableCell>
                            <TableCell>{item.post_settlement}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              {item.owners
                                .map((owner) => owner.individual_id)
                                .join(", ")}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </main>
  );
};

const DisplayField = ({ label, value }) => (
  <Grid item xs={3}>
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: "14px",
        color: "#101922",
      }}
    >
      {label}
    </Typography>
    <TextField
      fullWidth
      value={value || ""}
      variant="outlined"
      InputProps={{
        readOnly: true,
        sx: {
          fontSize: "14px",
          border: "1px solid #d8dce7",
          borderRadius: "4px",
          height: "40px",
          padding: "0px 12px",
        },
      }}
      InputLabelProps={{ shrink: false }}
    />
  </Grid>
);

const ClickableCard = ({ text, onClickHandler, showState }) => {
  return (
    <Card
      style={{
        backgroundColor: "#f6f7fb",
        padding: "20px 35px",
        height: "fit-content",
        cursor: "pointer",
        marginBottom: "25px",
        transition: "transform 0.2s, box-shadow 0.2s",
        boxShadow: showState
          ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
          : "0px 2px 5px rgba(0, 0, 0, 0.1)",
      }}
      onClick={() => {
        onClickHandler();
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{text}</Typography>
        {showState ? (
          <ExpandLess fontSize="medium" style={{ color: "#757575" }} />
        ) : (
          <ExpandMore fontSize="medium" style={{ color: "#757575" }} />
        )}
      </div>
    </Card>
  );
};

export default ViewInfynityClients;
