import React, { Fragment } from 'react';
import styled from 'styled-components';
import BaseInput from 'components/Styled/TextInput';
import BaseTextArea from 'components/Styled/TextArea';
import BaseLabelledInput from 'components/Common/LabelledInput';
import BaseSelect from 'components/Common/Select';
import BaseStyledSelect from 'components/Styled/Select';
import BaseAddressInput from 'components/Common/AddressInput';
import BaseBoxlessSelect from 'components/Common/BoxlessSelect';
import BaseCheckbox from 'components/Common/Checkbox';
import Search from 'components/Styled/Search';
import { MultiSelect as BaseMultiSelect } from 'components/Common/MultiSelect';
import { Field } from 'redux-form';
import { FormToggleButton } from 'components/Styled/CheckBox';
import Radio from 'components/Common/Radio';
import ContentEditable from './EditableContent';
import { RichTextArea as RTA } from './RichTextInput';

import { createNumberMask, createTextMask } from 'redux-form-input-masks';

export const EditableContent = ContentEditable;
export const RichTextArea = RTA;

const ErrorMessage = styled.div`
  position: absolute;
  color: red;
  font-size: 11px;
  padding-top: 4px;
`;

export const Input = ({displayValue, ...props}) => (
  <Field 
    {...props}
    component={InputBase}
    displayValue={displayValue}
  />
);

const InputBase = ({meta: {touched, error}, input, className, displayValue, ...props}) => {
  className = className + ((touched && error) ? ' error' : '');

  return (
    <Fragment>
     {displayValue != undefined && <span style={{marginTop: "13px", marginLeft:"10px", position: "absolute" }}>{displayValue}</span> }
      <BaseInput 
        component='input' 
        className={className}
        {...props} {...input}/>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
    </Fragment>
  );
}


const InputPercent = ({meta: {touched, error}, input, ...props}) => 
  <InputPercentWrapper 
    as='div'
    className={touched && error && 'error'} 
    {...props}>
    <input type='number' max='100' step='any' {...input}/>
  </InputPercentWrapper>

const SearchBase = ({meta: {touched, error}, input, ...props}) => (
  <Fragment>
    <Search 
      component='input' 
      className={touched && error && 'error'}
      {...props} {...input}/>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
);

const MultiSelectBase = ({meta: {touched, error}, input, ...props}) => (
  <Fragment>
    <BaseMultiSelect 
      //className={touched && error && 'error'}
      {...props} {...input}/>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
);

const InputPercentWrapper = styled(BaseInput)`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  margin-right: 40px;
  margin-bottom: 20px;
  background: white;
  width: ${p => p.width || '90%'};

  &:focus-within {
    border: 1px solid #2291FF !important;
    box-shadow: inset 0 0 0 2px rgba(34,145,255,0.20) !important;
  }
  
  &::before {
    position: absolute;
    top: 13px;
    left: 10px;
    content: '%';
  }

  input {
    background-color: transparent !important;
    height: 100%;
    width: 100% !important;
    border: 0 !important;
    box-shadow: none !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    outline:none;
    font-size: 14px;
  }
`;

// export const NumberInput = ({...props}) => (
//   <Field 
//     {...props}
//     component={NumberInputBase}
//   />
// );

// const NumberInputBase = ({meta: {touched, error}, input, ...props}) => (
  
//   <Fragment>
//     <NumberInputComponent 
//       component='input' 
//       className={touched && error && 'error'}
//       {...props} {...input}/>
//     {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
//   </Fragment>
// );

const moneyMask = createNumberMask({
  prefix: '$ ',
  locale: 'en-US',
  allowEmpty: true,
});

const numberMask = createNumberMask({
  locale: 'en-US',
  allowEmpty: true,
});

const moneyMaskInt = createNumberMask({
  prefix: '$ ',
  locale: 'en-US',
  allowEmpty: true,
  allowNegative: true,
});


const monthMask = createNumberMask({
  suffix: ' months',
  locale: 'en-US',
  allowEmpty: true,
});


const yearMask = createNumberMask({
  suffix: ' years',
  locale: 'en-US',
  allowEmpty: true,
});

const dateMask = createTextMask({
  pattern: '99/99/9999',
  guide: false,
});

export const DateInput = ({...props}) => (
  <Field 
    {...props}
    {...dateMask}
    component={InputBase}
  />
); 


export const MoneyInput = ({...props}) => (
  <Field 
    {...props}
    {...moneyMask}
    component={InputBase}
    placeholder='$'
  />
); 

export const NumberInput = ({...props}) => (
  <Field 
    {...props}
    {...numberMask}
    component={InputBase}
    placeholder='0'
  />
); 

export const PercentInput = ({...props}) => (
  <Field 
    {...props}
    component={InputPercent}
    type='number'
    placeholder='%'
    //validationPercent
  />
); 

export const MonthInput = ({...props}) => (
  <Field 
    {...props}
    {...monthMask}
    component={InputBase}
    placeholder='months'
    //validationPercent
  />
); 

export const YearInput = ({...props}) => (
  <Field 
    {...props}
    {...yearMask}
    component={InputBase}
    placeholder='years'
    //validationPercent
  />
); 

export const MoneyInputInt = ({...props}) => (
  <Field 
    {...props}
    {...moneyMaskInt}
    component={InputBase}
    placeholder='$'
  />
); 

export const TextArea = props => (
  <BaseTextArea as={Field} component='textarea' {...props}/>
);

export const LabelledInput = props => (
  <Field component={BaseLabelledInput} {...props}/>
);

export const RadioBox = 
props => (
  <Field component={Radio} {...props}/>
);

export const Select = 
props => (
  <Field component={BaseSelect} {...props}/>
);


const MultiSelectFormat = (value) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return [];
  }
}

export const MultiSelect = 
props => (
  <Field component={MultiSelectBase} normalize={JSON.stringify} format={MultiSelectFormat} {...props}/>
);

export const Checkbox = 
props => (
  <Field component={BaseCheckbox} {...props}/>
);

export const SearchBar = 
props => (
  <Field component={SearchBase} {...props}/>
);


export const BoxlessSelect = 
props => (
  <Field component={BaseBoxlessSelect} {...props}/>
);

export const StyledSelect = 
props => (
  <Field component={StyledSelectBase} {...props}/>
);


const StyledSelectBase = ({meta: {touched, error}, input, ...props}) => (
  
  <Fragment>
    <BaseStyledSelect 
      component='select' 
      className={touched && error && 'error'}
      {...props} {...input}/>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
);

export const AddressInput = props => (
  <Field component={BaseAddressInput} {...props}/>
);

export const ToggleButton = FormToggleButton;

