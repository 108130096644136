import React from 'react';
import { Switch, Route } from 'react-router-dom';

const TitleRoutes = () => (
  <Switch>
    <Route exact path="/newsfeed" component={()=><span>News Feed</span>}/>
    <Route exact path="/applications" component={()=><span>Applications</span>}/>
    <Route path="/applications/view" component={()=><span>Application Details</span>}/>
    <Route exact path="/clients" component={()=><span>Clients</span>}/>
    <Route exact path="/clients/add" component={()=><span>Add Client</span>}/>
    <Route path="/clients/view" component={()=><span>Client Details</span>}/>
    <Route path="/eligibility/view" component={()=><span>Eligibility Check Details</span>}/>
    <Route path="/eligibility/archive" component={()=><span>Eligibility Check Archive</span>}/>
    <Route path="/qualifiers" component={()=><span>Loan Processing</span>}/>
    <Route path="/referrals" component={()=><span>Referral Pipeline</span>}/>
    <Route path="/deals" component={()=><span>Submission Pipeline</span>}/>
    <Route path="/in-progress" component={()=><span>Pre-Submission Pipeline</span>}/>
    <Route path="/new-leads" component={()=><span>New Leads Pipeline</span>}/>
    <Route exact path="/lenders" component={()=><span>Lenders</span>}/>
    <Route exact path="/lenders/add" component={()=><span>Add Lender</span>}/>
    <Route exact path="/lenders/edit" component={()=><span>Edit Lender</span>}/>
    <Route exact path="/products" component={()=><span>Loan Products</span>}/>
    <Route exact path="/products/view" component={()=><span>Loan Product Details</span>}/>
    <Route component={()=><span>Welcome to Secure Finance</span>}/>
  </Switch>
);

export default TitleRoutes;