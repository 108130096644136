import React from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import { Form, reduxForm } from 'redux-form';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Input, Select, TextArea, ToggleButton } from 'components/Form';
import { DeleteButton } from 'views/Settings/Components/Shared';
import { useDispatch, useSelector } from 'react-redux';
import { required } from 'util/validation';
import { removeStage } from 'modules/applications/actions';

const senderOptions = [
  { name: 'Secure Finance', value: 'loanbase' },
  { name: 'Secure Finance (Can receive)', value: 'can_receive' }, 
  { name: 'Assigned Broker', value: 'broker' },
  { name: 'Assigned Processor', value: 'processor' },
];

const SmsTemplateModalBase = ({ modal, onSubmit, handleSubmit, initialize, reset, ...props }) => {
  
  const dispatch = useDispatch();
  const stages = useSelector(state => state.applications.stages);
  const templatePage = useSelector(state => state.form[props.form].values.page);
  const templateId = useSelector(state => state.form[props.form].values.id);

  const submit = values => {
    if (values.id === -1) {
      const maxId = Math.max(...Object.keys(stages));
      values.id = maxId + 1;
      values.isNew = true;
    }
    if (!values.smsSender) values.smsSender = 'loanbase';
    onSubmit(values);
    modal.close();
  };

  const pages = Object.values(stages)
    .sort((a,b) => a.stageIndex - b.stageIndex)
    .reduce((obj, stage) => ({
      ...obj,
      [stage.page]: [ ...(obj[stage.page] ?? []), stage ]
    }), {});

  let templateOptions = [ 
    ...(pages['deals'] ?? []), 
    ...(pages['in-progress'] ?? []), 
    ...(pages['new-leads'] ?? []), 
    ...(pages['qualifiers'] ?? []), 
    ...(pages['referrals'] ?? []), 
    ...(pages['sms-template'] ?? [])
  ].map(stage => {
    let name;
    let stageName = capitalizeFirstLetter(stage.page);
    if (stage.page === 'new-leads') stageName = 'New Leads';
    if (stage.page === 'in-progress') stageName = 'In Progress';
    if (stage.page !== 'sms-template') name = `${stageName} - ${stage.name}`;
    else name = stage.name;
    return { name, value: stage.id };
  });

  templateOptions = [ { name: 'Add new template', value: -1 }, ...templateOptions ];

  const changeTemplate = id => {
    const template = id === -1 ? { id: -1, page: 'sms-template' } : stages[id];
    console.log(template);
    initialize(template);
    reset();
  };

  const deleteTemplate = () => {
    dispatch(removeStage(templateId));
    initialize({ id: -1, page: 'sms-template' });
    reset();
  };

  return (
    <ModalBox style={{ width: '540px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Stage SMS Automation</SmallHeading>
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='320px'>
            <Label>Please select a template</Label>
            <Select width='300px' name='id' options={templateOptions} onChange={changeTemplate}/>
          </Column>
          {templatePage === 'sms-template' && templateId !== -1 &&
            <Column width='100px'>
              <DeleteButton style={{ position: 'relative', top: '22px'}} onDelete={deleteTemplate}/>
            </Column>
          }
        </Row>
        {templatePage === 'sms-template' &&
          <Row margin='0 0 20px 0'>
            <Label>Template Name</Label> 
            <Input width='300px' name='name' validate={required} />
          </Row>
        } 
        <Row margin='0 0 20px 0'>
          <Label>SMS Sender</Label>
          <Select width='300px' options={senderOptions} name='smsSender'/>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>SMS Message</Label>
          <SubLabel>{'Allowable strings: {broker}, {brokerfirst}, {client}, {clientfirst}, {processor}, {processorfirst}, {lender}, {brokeremail}, {processoremail}, {clientemail}, {calendly}'}</SubLabel>
          <TextArea name='smsTemplate'/>
        </Row>
        <Row margin='0 0 3px 0'>
          <Column width='50%'>
            <Label>
              <ToggleButton name='smsEnabled' valueChecked={1} valueUnchecked={0}/>  
              SMS Automation Enabled
            </Label>
          </Column>
          <Column width='50%'>
            <Label>
              <ToggleButton name='smsDebug' valueChecked={1} valueUnchecked={0}/>
              Debug Mode
            </Label>
          </Column>
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='50%'>
            <Label>
              <ToggleButton name='smsNotifyBroker' valueChecked={1} valueUnchecked={0}/>
              Notify Broker
            </Label>
          </Column>
          <Column width='50%'>
            <Label>
              <ToggleButton name='smsNotifyProcessor' valueChecked={1} valueUnchecked={0}/>
              Notify Processor
            </Label>
          </Column>
        </Row>
        <Row style={{ marginTop: '20px'}}> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={modal.close} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Save</ButtonPrimary>
          </Column>
        </Row>
      </Form> 
    </ModalBox>
  );
};

const SmsTemplateModal = reduxForm({ form: 'sms-template'})(SmsTemplateModalBase);

export default SmsTemplateModal;

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 26px !important;
  margin-top: -20px;
`;

const SubLabel = styled.div`
  color: #223241;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 12px;
`;

function capitalizeFirstLetter(string) {  
  return string[0].toUpperCase() +  string.slice(1); 
} 