import { hostUrl } from "modules";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LollipopChart from "../PortfolioDashboard/LollipopChart/LollipopChart";
import * as XLSX from "xlsx";
import "./OpportunityDashboard.css";
import VerticalLollipopGraph from "../InterestRateBreakdown/InterestRateBreakdown";
import { Typography } from "@mui/material";

const OpporunityDashboard = ({ goBack }) => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [data, setData] = useState({
    interestRateBreakdown: null,
    interestRateVariance: null,
    payedOffLoanBreakdown: null,
    fixedTermEnding: null,
    superFund: null,
  });

  const [breakdownData, setbreakdownData] = useState([]);
  const [superFundData, setSuperFundData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(currentDate);

  const fetchData = async (fromDate, toDate) => {
    setLoading(true);
    setError(null);

    try {
      const requests = [
        fetch(
          `${hostUrl}/opportunity-dashboard/interest-rate-breakdown?fromDate=${fromDate}&toDate=${toDate}`
        ),
        fetch(
          `${hostUrl}/opportunity-dashboard/interest-rate-variance?fromDate=${fromDate}&toDate=${toDate}`
        ),
        fetch(
          `${hostUrl}/opportunity-dashboard/payed-off-loan-breakdown?fromDate=${fromDate}&toDate=${toDate}`
        ),
        fetch(
          `${hostUrl}/opportunity-dashboard/fixed-term-breakdown?fromDate=${fromDate}&toDate=${toDate}`
        ),
        fetch(
          `${hostUrl}/opportunity-dashboard/super-fund?fromDate=${fromDate}&toDate=${toDate}`
        ),
      ];

      const responses = await Promise.all(requests);
      const results = await Promise.all(responses.map((res) => res.json()));

      setData({
        interestRateBreakdown: results[0],
        interestRateVariance: results[1],
        payedOffLoanBreakdown: results[2],
        fixedTermEnding: results[3],
        superFund: results[4],
      });
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];
    fetchData(start, end);
  }, []);

  useEffect(() => {
    if (data.interestRateBreakdown) {
      const output = Object.keys(
        data.interestRateBreakdown?.interestRateBreakdown
      )?.map((key) => ({
        xAxis: key,
        yAxis: data.interestRateBreakdown?.interestRateBreakdown[key],
      }));
      setbreakdownData(output);
    }
  }, [data.interestRateBreakdown]);

  useEffect(() => {
    if (data.superFund?.superBalanceDistribution) {
      const output = Object.keys(data.superFund?.superBalanceDistribution)?.map(
        (key) => ({
          xAxis: key,
          yAxis: data.superFund?.superBalanceDistribution[key],
        })
      );
      setSuperFundData(output);
    }
  }, [data?.superFund]);

  const handleLoadData = () => {
    const start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];
    fetchData(start, end);
  };

  const getInterestRateBreakdownDownload = () => {
    const excelData = data.interestRateBreakdown?.report?.map((item) => ({
      "Client Name": item.clientName,
      "Current Rate": item.currentRate,
      "Current Rate Date": item.currentRateDate,
      "Lender Id": item.lenderId,
      "Lender Name": item.lenderName,
      "Loan Amount": item.loanAmount,
      "Settled Rate": item.settledRate,
      "Settled Rate Date": item.settledRateDate,
    }));

    return excelData;
  };

  const getSuperFundDownload = () => {
    const excelData = data.superFund?.report?.map((item) => ({
      "Client Name": item.clientName,
      "Current Rate": item.currentRate,
      "Current Rate Date": item.currentRateDate,
      "Fixed Expiry": item.fixedExpiry,
      "Lender Id": item.lenderId,
      "Lender Name": item.lenderName,
      "Loan Amount": item.loanAmount,
      "Outstanding Balance": item.outstandingBalance,
      "Settled Rate": item.settledRate,
      "Settled Rate Date": item.settledRateDate,
      "Super Balance": item.superBalance,
    }));

    return excelData;
  };

  const getFixedRateEndingDownload = () => {
    const excelData = data.fixedTermEnding?.report?.map((item) => ({
      "Lender Id": item.lenderId,
      "Lender Name": item.lenderName,
      "Client Name": item.clientName,
      "Settled Rate": item.settledRate,
      "Settled Rate Date": item.settledRateDate,
      "Current Rate": item.currentRate,
      "Current Rate Date": item.currentRateDate,
      "Loan Amount": item.loanAmount,
      "Outstanding Balance": item.outstandingBalance,
      "Interest Type Date": item.interestTypeDate,
    }));

    return excelData;
  };

  const getPayedOffLoanBreakdownDownload = () => {
    const excelData = data.payedOffLoanBreakdown?.report?.map((item) => ({
      "Client Name": item.clientName,
      "Current Rate": item.currentRate,
      "Current Rate Date": item.currentRateDate,
      "Lender Id": item.lenderId,
      "Lender Name": item.lenderName,
      "Loan Amount": item.loanAmount,
      "Outstanding Balance": item.outstandingBalance,
      "Settled Rate": item.settledRate,
      "Settled Rate Date": item.settledRateDate,
    }));

    return excelData;
  };

  const downloadExcel = (formattedData, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  console.log("superFundData", superFundData);
  console.log("breakDown", breakdownData);

  return (
    <div>
      <div className="header">
        <div className="titleAndDateControls">
          <div className="datePickerContainer">
            <label htmlFor="startDate" className="dateLabel">
              From:
            </label>
            <DatePicker
              id="startDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<CustomInput />}
            />
          </div>
          <div className="datePickerContainer">
            <label htmlFor="endDate" className="dateLabel">
              To:
            </label>
            <DatePicker
              id="endDate"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              minDate={startDate}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<CustomInput />}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <button onClick={handleLoadData} className="loadButton">
              {loading ? "Loading..." : "Load"}
            </button>
            <div style={{ marginLeft: "250px" }}>
              <Typography variant="h5">Opportunity Dashboard</Typography>
            </div>
          </div>
        </div>

        <div className="controls">
          <button className="loadButton" onClick={goBack}>
            Dashboard
          </button>
        </div>
      </div>

      {loading && <div className="loading">Loading...</div>}
      {!loading && (
        <div className="contentContainer">
          <div className="chartsContainer">
            <div className="chartContainerCommon">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="chartTitle">Interest Breakdown</span>
                <button
                  onClick={() => {
                    downloadExcel(
                      getInterestRateBreakdownDownload(),
                      "InterestRateBreakdown"
                    );
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#284867",
                    padding: "6px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
                    transition: "boxShadow 0.3s ease",
                    marginRight: "15px",
                  }}
                >
                  View Report
                </button>
              </span>
              <VerticalLollipopGraph
                data={breakdownData}
                xAxisLabel="Loans"
                yAxisLabel="Interest Loans"
              />
            </div>
            <div className="chartContainerCommon">
              <span className="chartTitle">Interest Rate Variance</span>
              <LollipopChart
                data={data.interestRateVariance}
                yAxisLabel="Loan Value"
                xAxisLabel="Loans"
                reverseYAxis={false}
                lineColor="#D3D3D3"
                progressBarColor="#4A78A8"
                circleColor="#F09C70"
                textColor="#284867"
                graphMargin={{ top: 20, right: 30, bottom: 30, left: 150 }}
              />
            </div>
          </div>
          <div className="chartsContainer">
            <div className="chartContainerCommon">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="chartTitle">Paid Off Loan Breakdown</span>
                <button
                  onClick={() => {
                    downloadExcel(
                      getPayedOffLoanBreakdownDownload(),
                      "PaidOffLoanBreakdown"
                    );
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#284867",
                    padding: "6px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
                    transition: "boxShadow 0.3s ease",
                    marginRight: "15px",
                  }}
                >
                  View Report
                </button>
              </span>
              <LollipopChart
                data={data.payedOffLoanBreakdown?.loanBalanceDistribution}
                yAxisLabel="Loan Value"
                xAxisLabel="Loans"
                reverseYAxis={false}
                graphMargin={{ top: 20, right: 30, bottom: 30, left: 150 }}
              />
            </div>
            <div className="chartContainerCommon">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="chartTitle">Fixed Term Ending</span>
                <button
                  onClick={() => {
                    downloadExcel(
                      getFixedRateEndingDownload(),
                      "fixedTermEnding"
                    );
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#284867",
                    padding: "6px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
                    transition: "boxShadow 0.3s ease",
                    marginRight: "15px",
                  }}
                >
                  View Report
                </button>
              </span>
              <LollipopChart
                data={data.fixedTermEnding?.fixedInterestBreakdown}
                yAxisLabel="Months"
                xAxisLabel="Loans"
                reverseYAxis={false}
                lineColor="#F66D66"
                progressBarColor="#F66D66"
                circleColor="#F66D66"
                backgroundBarColor="#FDDADA"
                graphMargin={{ top: 20, right: 30, bottom: 30, left: 120 }}
              />
            </div>
          </div>
          <div className="chartsContainer">
            <div className="chartContainerCommon half">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="chartTitle">Super Fund on Client Settled Deals</span>
                <button
                  onClick={() => {
                    downloadExcel(getSuperFundDownload(), "SuperFundReport");
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#284867",
                    padding: "6px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)",
                    transition: "boxShadow 0.3s ease",
                    marginRight: "15px",
                  }}
                >
                  View Report
                </button>
              </span>
              <VerticalLollipopGraph
                data={superFundData}
                xAxisLabel={"Super Fund"}
                yAxisLabel={"Clients"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpporunityDashboard;

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="datePickerCustomInput" onClick={onClick} ref={ref}>
    {value}
  </button>
));
