import React from "react";
import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";

const LendersChart = ({ data }) => {
  const COLORS = [
    "#284867",
    "#3A5A87",
    "#4B6DAB",
    "#5D80D0",
    "#6E94F2",
    "#28486780",
  ];
  const totalApplications = data?.totalApplications;

  const lendersData = data?.lenders.filter(
    (lender) => lender.applicationCount > 0
  );

  
  const renderCustomLabel = ({ x, y, value }) => {
    return (
      <text
        x={x}
        y={y-5}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontWeight: "bold", fontSize: "16px" }}
      >
        {value}
      </text>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "px",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "relative", width: "450px", height: "450px" }}>
        <PieChart width={450} height={450}>
          <Pie
            data={lendersData}
            dataKey="applicationCount"
            nameKey="lenderName"
            cx="50%"
            cy="50%"
            innerRadius={110}
            outerRadius={160}
            fill="#8884d8"
            paddingAngle={0}
            startAngle={180}
            endAngle={-270}
            label={renderCustomLabel}  
          >
            {lendersData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>

        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#284867",
          }}
        >
          <h3>Total</h3>
          <p style={{ fontSize: "30px", fontWeight: "bold" }}>
            {totalApplications}
          </p>
        </div>
      </div>

      <div style={{ marginLeft: "20px" }}>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          {lendersData?.map((lender, index) => (
            <li key={lender.lenderName} style={{ marginBottom: "20px" }}>
              <span
                style={{
                  display: "inline-block",
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  backgroundColor: COLORS[index % COLORS.length],
                  marginRight: "8px",
                }}
              ></span>
              {lender.lenderName} - <span style={{ fontWeight: 800 }}> {lender.percentage}%</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LendersChart;
