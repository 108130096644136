import React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

const UploadModal = ({
  isOpen,
  onClose,
  onUpload,
  file,
  date,
  setFile,
  setDate,
  setUpfront,
  setTrial,
  upfront,
  trial,
}) => {
  // Function to handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Function to handle date change
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleUpfrontChange = (event) => {
    setUpfront(event.target.value);
  };
  const handleTrialChange = (event) => {
    setTrial(event.target.value);
  };

  // Function to handle form submission
  const handleUpload = () => {
    if (file && date) {
      onUpload(file, date);
    } else {
      alert("Please select a file and a date.");
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Upload Excel File and Date
        </Typography>

        <TextField
          label="Select Date"
          type="month"
          fullWidth
          margin="normal"
          value={date}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Total Upfront"
          type="number"
          fullWidth
          margin="normal"
          onChange={handleUpfrontChange}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Total Trial"
          type="number"
          fullWidth
          margin="normal"
          onChange={handleTrialChange}
          InputLabelProps={{ shrink: true }}
        />

        <Button
          variant="contained"
          component="label"
          sx={{
            width: "100%",
            mt: 2,
            backgroundColor: "#284867",
            color: "white",
            "&:hover": {
              backgroundColor: "#4e7ba6",
            },
          }}
        >
          Select Excel File
          <input
            type="file"
            accept=".xls, .xlsx"
            hidden
            onChange={handleFileChange}
          />
        </Button>

        {file && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            Selected file: {file.name}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: "#284867",
            color: "white",
            "&:hover": {
              backgroundColor: "#4e7ba6",
            },
          }}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Box>
    </Modal>
  );
};

export default UploadModal;
