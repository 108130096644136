// src/components/SideBar/SideBar.js

import React from "react";
import { NavLink } from "react-router-dom";
import { showUnfinished } from "modules";
import { useSelector } from "react-redux";
import clsx from "clsx";
import chevronLeft from "../../img/icons/chevron-left.svg";
import chevronRight from "../../img/icons/chevron-right.svg";

const Sidebar = () => {
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPipelineMenuOpen, setPipelineMenuOpen] = React.useState(false);
  const [isOppPipelineMenuOpen, setOppPipelineMenuOpen] = React.useState(false);
  const [isSettingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const [isHelpMenuOpen, setHelpMenuOpen] = React.useState(false);

  const togglePipelineMenu = () => {
    setPipelineMenuOpen(!isPipelineMenuOpen);
  };

  const toggleOppPipelineMenu = () => {
    setOppPipelineMenuOpen(!isOppPipelineMenuOpen);
  };

  const toggleSettingMenu = () => {
    setSettingsMenuOpen(!isSettingsMenuOpen);
  };

  const toggleHelpMenu = () => {
    setHelpMenuOpen(!isHelpMenuOpen);
  };

  return (
    <div className={clsx("sidebar", { close: isOpen })}>
      <nav>
        <ul>
          <li className="toggle" onClick={() => setIsOpen((prev) => !prev)}>
            {isOpen ? <img src={chevronRight} /> : <img src={chevronLeft} />}
          </li>
          <li className="menu-item-12">
            <NavLink activeClassName="current" to="/dashboard-view">
              Dashboard
            </NavLink>
          </li>

          <li
            className={clsx({
              "menu-item-19": !isPipelineMenuOpen,
              "sub-item-open": isPipelineMenuOpen,
            })}
            onClick={togglePipelineMenu}
          >
            <a>Pipelines</a>
          </li>

          {isPipelineMenuOpen && (
            <li className={clsx("menu-item-9", { "sub-item": !isOpen })}>
              <NavLink activeClassName="current" to="/deals">
                Submission
              </NavLink>
            </li>
          )}
          {user.userType !== "processor" && (
            <>
              {isPipelineMenuOpen && (
                <li className={clsx("menu-item-11", { "sub-item": !isOpen })}>
                  <NavLink activeClassName="current" to="/in-progress">
                    Pre-submission
                  </NavLink>
                </li>
              )}
              <li
                className={clsx({
                  "menu-item-19": !isOppPipelineMenuOpen,
                  "sub-item-open": isOppPipelineMenuOpen,
                })}
                onClick={toggleOppPipelineMenu}
              >
                <a>Opp Pipelines</a>
              </li>
              {isOppPipelineMenuOpen && (
                <>
                  <li className={clsx("menu-item-10", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/new-leads">
                      New Leads
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-8", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/referrals">
                      Referrals
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-4", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/qualifiers">
                      Loan Processing
                    </NavLink>
                  </li>
                </>
              )}
              {showUnfinished && (
                <li className="menu-item-1">
                  <NavLink activeClassName="current" exact to="/newsfeed">
                    News Feed
                  </NavLink>
                </li>
              )}

              {showUnfinished && (
                <li className="menu-item-3">
                  <NavLink activeClassName="current" to="/applications">
                    Applications
                  </NavLink>
                </li>
              )}
              <li
                className={clsx({
                  "menu-item-19": !isSettingsMenuOpen,
                  "sub-item-open": isSettingsMenuOpen,
                })}
                onClick={toggleSettingMenu}
              >
                <a>Admin Settings</a>
              </li>
              {isSettingsMenuOpen && (
                <>
                  <li className={clsx("menu-item-5", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/lenders">
                      Lenders
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-6", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/products">
                      Loan Products
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-8", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/clients">
                      Clients
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-21", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/infinity-clients">
                      Infinity Clients
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-2", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/team">
                      Team
                    </NavLink>
                  </li>
                  <li className={clsx("menu-item-20", { "sub-item": !isOpen })}>
                    <NavLink activeClassName="current" to="/settings">
                      Settings
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
          <li
            className={clsx({
              "menu-item-19": !isHelpMenuOpen,
              "sub-item-open": isHelpMenuOpen,
            })}
            onClick={toggleHelpMenu}
          >
            <a>Help</a>
          </li>
          {isHelpMenuOpen && (
            <>
              <li className={clsx("menu-item-16", { "sub-item": !isOpen })}>
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/11J7fIzSI9c1vkV43ALb2Oe5w6g9cHMpM/view?usp=sharing"
                >
                  Customer Journey
                </a>
              </li>
              <li className={clsx("menu-item-17", { "sub-item": !isOpen })}>
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1lwby4Dy0fuEC7-uhuEUeAjDdvPngg2Fk/view?usp=sharing"
                >
                  Broker Journey
                </a>
              </li>
              <li className={clsx("menu-item-18", { "sub-item": !isOpen })}>
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1z_-SMMhSZjWkwssUeYwHAjOzFiDO5Y2H/view?usp=sharing"
                >
                  Admin Settings
                </a>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
