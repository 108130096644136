import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul style={{ display: 'flex', justifyContent: 'flex-end', listStyleType: 'none', padding: 0 }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginRight: index === 0 ? '40px' : '20px', 
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: entry.color,
              marginRight: '8px',
            }}
          ></span>
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

const formatYAxis = (tickItem) => {
  if (tickItem >= 1000000) {
    return `$${(tickItem / 1000000).toFixed(1)}M`;
  } else if (tickItem >= 1000) {
    return `$${(tickItem / 1000).toFixed(1)}K`;
  }
  return `$${tickItem}`;
};

const formatNumberWithCommas = (number) => {
  return `$${number.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
        {payload.map((entry, index) => (
          <div 
            key={`tooltip-item-${index}`} 
            style={{ 
              margin: "5px 0",  
              color: entry.color, 
              display: 'flex', 
              justifyContent: 'space-between', 
              width: '100%' 
            }}
          >
            <span><strong>{entry.name}:</strong></span>
            <span>{formatNumberWithCommas(entry.value)}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

// Main Component
const TotalMonthlyCommissionGraph = ({ data }) => {
  const formatMonthYear = (tickItem) => {
    const date = new Date(tickItem);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${month}-${year}`;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
        barGap={3}
        barCategoryGap="5%"
      >
        <XAxis
          dataKey="month"
          tickFormatter={formatMonthYear}
          angle={60}
          textAnchor="start"
          tick={{ fontWeight: "bold" }}
        />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip 
          content={<CustomTooltip />} 
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={renderLegend} 
          verticalAlign="top"
          wrapperStyle={{
            paddingBottom: '30px',
          }}
        />
        <Bar dataKey="retained" fill="#A560FF" name="Retained" barSize={35} />
        <Bar dataKey="remitted" fill="#6CC4FF" name="Remitted" barSize={35} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TotalMonthlyCommissionGraph;
