import React, { useState, useEffect } from "react";
import Select from "react-select";
import OverDueTable from "./OverDueTable/OverDueTable";
import CommissionTable from "./CommissionTable";
import PostUnconditionalChart from "./PostUnconditionalChart/PostUnconditionalChart";
import SalesFigureChart from "./SalesFigureChart/SalesFigure";
import * as XLSX from "xlsx";
import { hostUrl } from "modules";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CommissionIcon from "../../img/icons/commission.png";
import AcquisitionIcon from "../../img/icons/acquisition.png";
import OpportunityIcon from "../../img/icons/opportunity.png";
import CVIcon from "../../img/icons/cv.png";
import "./datepicker.css";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import DonutChart from "./LenderRetainedChart/LenderRetainedChart";
import {
  formatDate,
  fetchDataRequest,
  fetchExcelFileStatusRequest,
  uploadExcelFileRequest,
} from "./dashboardUtils";
import CombinedChart from "./CombinedChart/CombinedChart";
import TotalUpfrontAndTrail from "./TotalUpfrontAndTrailGraph/totalUpfrontAndTrail";
import {
  chartOptions,
  activeClientBaseUrl,
  dashboardColors,
  dashboardColorsContrast,
  displayDashboardValues,
  styles,
} from "./dashboardConstants";
import UploadModal from "./UploadExcelDate/uploadExcelModal";
import OverwriteExcelConfirmationModal from "./UploadExcelDate/overwriteConfirmationModal";
import { CustomInput } from "./CustomDatePickerInput/CustomDatePickerInput";
import TotalMonthlyCommissionGraph from "./TotalMonthlyCommissionGraph/TotalMonthlyCommissionGraph";
import PortfolioDashboard from "./PortfolioDashboard/PortfolioDashboard";
import OpportunityDashboard from "./OpportunityDashboard/OpportunityDashboard";

const Dashboards = () => {
  //----------------STATES----------------
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 3,
    currentDate.getDate()
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedChart, setSelectedChart] = useState(chartOptions[0]);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedCommissionFromDate, setSelectedCommissionFromDate] = useState(
    new Date()
  );
  const [selectedCommissionToDate, setSelectedCommissionToDate] = useState(
    new Date()
  );
  const [payoutState, setPayoutState] = useState();
  const [lenderRetainedState, setLenderRetainedState] = useState();
  const [lenderLoanBalanceState, setLenderLoanBalanceState] = useState();
  const [lenderLoanMissingCustomersState, setLenderLoanMissingCustomersState] = useState();
  const [last12MonthsState, setLast12MonthsState] = useState();
  const [UpfrontAndTrailState, setUpfrontAndTrailState] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [date, setDate] = useState("");
  const [upfront, setUpfront] = useState(null);
  const [trial, setTrial] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [displayDashboard, setDisplayDashboard] = useState(
    displayDashboardValues.main
  );
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const defaultStartYear = currentMonth < 6 ? currentYear-1 : currentYear;

  

  const financialYearOptions = Array.from(
    { length: 10 },
    (_, i) => {
      const startYear = new Date().getFullYear() - i;
      const startOfNextYear = new Date(startYear + 1, 0, 1); 
      return {
        value: startOfNextYear.toISOString().split("T")[0], 
        label: `${startYear}-${startYear + 1}`,
      };
    }
  );
  
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(
    financialYearOptions.find(option => option.label === `${defaultStartYear}-${defaultStartYear+1}`)
  );

  const [selectedFinancialcommYear, setSelectedFinancialcommYear] = useState(
    financialYearOptions.find(option => option.label === `${defaultStartYear}-${defaultStartYear+1}`)
  );

  const fromDate = formatDate(startDate);
  const toDate = formatDate(endDate);

  const getDynamicURL = () => {
    if (selectedChart.value === "salesFigure") {
      return `${hostUrl}/salesFigure?fromDate=${fromDate}&toDate=${toDate}`;
    } else if (selectedChart.value === "postUnconditional") {
      return `${hostUrl}/postUnconditional?fromDate=${fromDate}&toDate=${toDate}`;
    }
  };

  //----------------WORKING ON EXTRACTION OF THE BELOW METHOD, DUE TO TECHNICAL IMPORT ISSUES----------------
  const fetchCommissionDashboardDataRequest = async () => {
    try {
      const FromformatedDate = formatDate(new Date(selectedCommissionFromDate));
      const ToformatedDate = formatDate(new Date(selectedCommissionToDate));
      
      if (new Date(selectedCommissionToDate) < new Date(selectedCommissionFromDate)) {
        alert("Error: 'To' date cannot be earlier than 'From' date.");
        return; // Exit the function early if the dates are invalid
      }
      
      const financialYear = selectedFinancialYear?.value;
      const commfinancialYear = selectedFinancialcommYear?.value; 
      
      const [
        payouts,
        lenderRetained,
        lenderLoanBalance,
        missingCustomers,
        last12Months,
        upfrontAndTrail,
      ] = await Promise.all([
        fetch(
          `${hostUrl}${activeClientBaseUrl}/payouts?fromDate=${FromformatedDate}&toDate=${ToformatedDate}`
        ),
        fetch(
          `${hostUrl}${activeClientBaseUrl}/lender-retained?fromDate=${FromformatedDate}&toDate=${ToformatedDate}`
        ),
        fetch(
          `${hostUrl}${activeClientBaseUrl}/lender-loan-balance?fromDate=${FromformatedDate}&toDate=${ToformatedDate}`
        ),
        fetch(
          `${hostUrl}${activeClientBaseUrl}/missing-customers?fromDate=${FromformatedDate}&toDate=${ToformatedDate}`
        ),
        fetch(
          `${hostUrl}${activeClientBaseUrl}/last-12-months/${commfinancialYear}`
        ),
        fetch(
          `${hostUrl}${activeClientBaseUrl}/totalUpfrontAndTrail/last-12-months/${financialYear}`
        ),
      ]);

      if (
        !payouts.ok ||
        !lenderRetained.ok ||
        !lenderLoanBalance.ok ||
        !missingCustomers.ok ||
        !last12Months.ok ||
        !upfrontAndTrail.ok
      ) {
        console.error("API PROBLEM");
        return;
      }

      const [
        payoutsResult,
        lenderRetainedResult,
        lenderLoanBalanceResult,
        missingCustomersResults,
        last12MonthsResult,
        upfrontAndTrailResult,
      ] = await Promise.all([
        payouts.json(),
        lenderRetained.json(),
        lenderLoanBalance.json(),
        missingCustomers.json(),
        last12Months.json(),
        upfrontAndTrail.json(),
      ]);

      const transformedRetained = lenderRetainedResult
        .map(
          (item, index) =>
            item.retained > 0 && {
              name: item.lender,
              value: item.retained,
              rValue: item.retainedValue,
              color:
                dashboardColorsContrast[index % dashboardColorsContrast.length],
            }
        )
        .filter((item) => item);

        const transformedLoanBalance = lenderLoanBalanceResult
          .map(
            (item, index) =>
              item.loanBalance > 0 && {
                name: item.lender,
                value: item.loanBalance,
                rValue: item.loanBalanceValue,
                color: dashboardColors[index % dashboardColors.length],
              }
          )
          .filter((item) => item);

          const transformedMissingCustomers = missingCustomersResults
          .map(
            (item, index) =>
              item.loanBalance > 0 && {
                name: item.clientName,
                value: item.missingMonth,
                color: dashboardColors[index % dashboardColors.length],
              }
          )
          .filter((item) => item);

          setPayoutState(payoutsResult);
          setLenderRetainedState(transformedRetained);
          setLenderLoanBalanceState(transformedLoanBalance);
          setLenderLoanMissingCustomersState(missingCustomersResults);
          setLast12MonthsState(last12MonthsResult);
          setUpfrontAndTrailState(upfrontAndTrailResult);
            } catch (error) {
              console.error("Error fetching data:", error);
            } finally {
              console.log("Fetch operation completed.");
            }
          };

  const handleChartChange = (selectedOption) => {
    setSelectedChart(selectedOption);
  };

  const salesFigureData = () => {
    const formatData = (item) => {
      const lastStageChange = item.stageChanges[item.stageChanges.length - 1];
      return {
        "Deal Type": item.dealType,
        "Deal Value": item.loanAmount,
        "Due Date": item.dueDate,
        "Deal Status": lastStageChange.stageName,
        "Stage Status": item.stageStatus,
        "Deal Description": item.dealDescription,
        "Application Number": item.applicationNo,
        "Application ID": item.id,
        Date: lastStageChange.timestamp,
        "Client Name": item.user_name,
        "Client SMS Verified Y/N": item.user_isMobileVerified ? "Yes" : "No",
        "Broker Name": item.broker_name,
        Lender: item.lenderId,
        Processor: item.processor_name,
      };
    };

    let combinedData = [];

    data.forEach((dayData) => {
      combinedData = [
        ...combinedData,
        ...(dayData.conditionalApproved || []).map(formatData),
        ...(dayData.unconditionalApproved || []).map(formatData),
        ...(dayData.loanSettled || []).map(formatData),
      ];
    });

    return combinedData;
  };

  const postConditionalData = () => {
    const formatData = (item) => {
      const lastStageChange = item.stageChanges[item.stageChanges.length - 1];
      return {
        "Deal Type": item.dealType,
        "Deal Value": item.loanAmount,
        "Due Date": item.dueDate,
        "Deal Status": lastStageChange.stageName,
        "Stage Status": item.stageStatus,
        "Deal Description": item.dealDescription,
        "Application Number": item.applicationNo,
        "Application ID": item.id,
        Date: lastStageChange.timestamp,
        "Client Name": item.user_name,
        "Client SMS Verified Y/N": item.user_isMobileVerified ? "Yes" : "No",
        "Broker Name": item.broker_name,
        Lender: item.lenderId,
        Processor: item.processor_name,
      };
    };

    let combinedData = [];

    data.forEach((dayData) => {
      combinedData = [
        ...combinedData,
        ...(dayData.loanSubmittedToLender || []).map(formatData),
        ...(dayData.settlementBooked || []).map(formatData),
        ...(dayData.loanOfferReceivedByBank || []).map(formatData),
        ...(dayData.unconditionallyApproved || []).map(formatData),
        ...(dayData.loanOfferSent || []).map(formatData),
      ];
    });

    return combinedData;
  };

  const downloadExcel = () => {
    const jsonData =
      selectedChart.value === "salesFigure"
        ? salesFigureData()
        : postConditionalData();
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${selectedChart.label}Report.xlsx`);
  };

  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleOpenExcelFileModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseExcelFileModal = () => {
    setFile(null);
    setDate("");
    setIsModalOpen(false);
  };

  const formatToDollar = (value) => {
    return value
      ? `$${value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : "$0.00";
  };
  
  const handleFinancialYearChange = (selectedOption) => {
    setSelectedFinancialYear(selectedOption);
  };

  const handleFinancialYearcommChange = (selectedOption) => {
    setSelectedFinancialcommYear(selectedOption);
  };


  useEffect(() => {
    fetchCommissionDashboardDataRequest();
    fetchDataRequest({ getDynamicURL, setLoading, setData, setError });
  }, [selectedChart,selectedFinancialYear,selectedFinancialcommYear]);

  return (
    <main style={styles.main}>
      {displayDashboard === displayDashboardValues.sales && (
        <div>
          <div style={styles.row}>
            <div style={styles.contentContainer}>
              <div style={styles.header}>
                <div style={styles.titleAndDateControls}>
                  <span style={styles.title}>{selectedChart.label}</span>

                  <div style={styles.datePickerContainer}>
                    <label htmlFor="startDate" style={styles.dateLabel}>
                      From:
                    </label>
                    <DatePicker
                      id="startDate"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<CustomInput id="startDate" />}
                    />
                  </div>
                  <div style={styles.datePickerContainer}>
                    <label htmlFor="endDate" style={styles.dateLabel}>
                      To:
                    </label>
                    <DatePicker
                      id="endDate"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      minDate={startDate}
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<CustomInput id="endDate" />}
                    />
                  </div>
                  <button
                    onClick={() =>
                      fetchDataRequest({
                        getDynamicURL,
                        setLoading,
                        setData,
                        setError,
                      })
                    }
                    style={styles.loadButton}
                  >
                    Load
                  </button>
                </div>
                <div style={styles.controls}>
                  <div style={styles.dropdownContainer}>
                    <Select
                      value={selectedChart}
                      onChange={handleChartChange}
                      options={chartOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "#284867",
                        },
                      })}
                    />
                  </div>
                  {data && (
                    <button
                      onClick={downloadExcel}
                      style={styles.viewReportButton}
                    >
                      View Report
                    </button>
                  )}

                  <button
                    style={styles.loadButton}
                    onClick={() =>
                      setDisplayDashboard(displayDashboardValues.main)
                    }
                  >
                    Dashboard
                  </button>
                </div>
              </div>
              <div style={styles.chartContainer}>
                {loading ? (
                  <div style={styles.loading}>Loading...</div>
                ) : error ? (
                  <div>Error: {error}</div>
                ) : selectedChart.value === "salesFigure" ? (
                  <SalesFigureChart
                    data={data}
                    startDate={fromDate}
                    endDate={toDate}
                  />
                ) : (
                  <PostUnconditionalChart
                    data={data}
                    startDate={fromDate}
                    endDate={toDate}
                  />
                )}
              </div>
            </div>
          </div>

          <div style={styles.row}>
            <OverDueTable />
          </div>
        </div>
      )}
      {displayDashboard === displayDashboardValues.main && (
        <Box
          sx={{
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Grid container spacing={4} sx={{ height: "100%" }}>
            <Grid item xs={12} sm={6} sx={{ height: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                  backgroundColor: "#284867",
                  "&:hover": {
                    backgroundColor: "#4e7ba6",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                }}
                onClick={() =>
                  setDisplayDashboard(displayDashboardValues.commission)
                }
              >
                <img src={CommissionIcon} height={"120px"} />
                Commission
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ height: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                  backgroundColor: "#284867",
                  "&:hover": {
                    backgroundColor: "#4e7ba6",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                }}
                onClick={() =>
                  setDisplayDashboard(displayDashboardValues.sales)
                }
              >
                <img src={AcquisitionIcon} height={"120px"} />
                Sales/Overdue
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ height: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                  backgroundColor: "#284867",
                  "&:hover": {
                    backgroundColor: "#4e7ba6",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                }}
                onClick={() =>
                  setDisplayDashboard(displayDashboardValues.portfolio)
                }
              >
                <img src={CVIcon} height={"120px"} />
                Portfolio
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ height: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                  backgroundColor: "#284867",
                  "&:hover": {
                    backgroundColor: "#4e7ba6",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                }}
                onClick={() =>
                  setDisplayDashboard(displayDashboardValues.opportunity)
                }
              >
                <img src={OpportunityIcon} height={"120px"} />
                Opportunity
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {displayDashboard === displayDashboardValues.commission && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box display="flex" gap={1}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      htmlFor="FrommonthYear"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      From:
                    </label>
                    <DatePicker
                      id="FrommonthYear"
                      selected={selectedCommissionFromDate}
                      onChange={(date) => setSelectedCommissionFromDate(date)}
                      dateFormat="MMM/yyyy"
                      showMonthYearPicker
                      showTwoColumnMonthYearPicker
                      customInput={<CustomInput />}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      htmlFor="TomonthYear"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      To:
                    </label>
                    <DatePicker
                      id="TomonthYear"
                      selected={selectedCommissionToDate}
                      onChange={(date) => setSelectedCommissionToDate(date)}
                      dateFormat="MMM/yyyy"
                      showMonthYearPicker
                      showTwoColumnMonthYearPicker
                      customInput={<CustomInput />}
                    />
                  </div>
                  <button
                    onClick={fetchCommissionDashboardDataRequest}
                    style={styles.loadButton}
                  >
                    Load
                  </button>
                </Box>
                <Box sx={{ width: "50%", textAlign: "center" }}>
                  <Typography variant="h5" >Commission Dashboard</Typography>
                </Box>

                <Box display="flex" gap={2}>
                  <button
                    onClick={handleOpenExcelFileModal}
                    style={styles.loadButton}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        textWrap: "nowrap",
                      }}
                    >
                      <UploadFileIcon style={{ height: "18px" }} />
                      Upload Excel File
                    </span>
                  </button>

                  <button
                    style={styles.loadButton}
                    onClick={() =>
                      setDisplayDashboard(displayDashboardValues.main)
                    }
                  >
                    Dashboard
                  </button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper
                elevation={3}
                sx={{ padding: 2, textAlign: "center", height: "100%" }}
              >
                <Typography variant="h5" fontWeight={"light"}>
                  {payoutState?.totalPayouts}
                </Typography>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Total Payouts
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                elevation={3}
                sx={{ padding: 2, textAlign: "center", height: "100%" }}
              >
                <Typography variant="h5" fontWeight="light">
                  {formatToDollar(payoutState?.averageRemitted)}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  Average Remitted
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                elevation={3}
                sx={{ padding: 2, textAlign: "center", height: "100%" }}
              >
                <Typography variant="h5" fontWeight={"light"}>
                  {formatToDollar(payoutState?.sumOfRemitted)}
                </Typography>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Sum of Remitted
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                elevation={3}
                sx={{ padding: 2, textAlign: "center", height: "100%" }}
              >
                <Typography variant="h5" fontWeight={"light"}>
                  {formatToDollar(payoutState?.sumOfRetained)}
                </Typography>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Sum of Retained
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
                <Typography variant="h7" fontWeight={"bold"}>
                  Commission by Lender
                </Typography>
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {lenderRetainedState && (
                    <DonutChart data={lenderRetainedState} />
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
                <Typography variant="h7" fontWeight={"bold"}>
                  Loan Balance by Lender
                </Typography>
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {lenderLoanBalanceState && (
                    <DonutChart data={lenderLoanBalanceState} />
                  )}
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",mb: 2 }}>
                  <Typography variant="h7" fontWeight={"bold"}>
                    Upfront vs Trail Commission 
                  </Typography>  
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography variant="h7" fontWeight={"bold"}>
                    Financial Year:
                  </Typography>          
                    <Select
                      options={financialYearOptions}
                      value={selectedFinancialYear}
                      onChange={handleFinancialYearChange}
                      placeholder="Select Financial Year"
                      styles={{
                        container: (provided) => ({ ...provided, width: 170 }),
                        control: (provided) => ({
                          ...provided,
                          fontSize: '12px', 
                        }),
                        menu: (provided) => ({
                          ...provided,
                          fontSize: '12px', 
                        }),
                      }}
                    />
                    </Box>
                   </Box>
                   <Box
                   sx={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  >
                  {UpfrontAndTrailState && (
                    <TotalUpfrontAndTrail data={UpfrontAndTrailState} />
                  )}
                  
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>   
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",mb: 2 }}>
                  <Typography variant="h7" fontWeight={"bold"}>
                        Retained vs Remit Commission 
                  </Typography>  
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="h7" fontWeight={"bold"}>
                    Financial Year:
                  </Typography>          
                    <Select
                      options={financialYearOptions}
                      value={selectedFinancialcommYear}
                      onChange={handleFinancialYearcommChange}
                      placeholder="Select Financial Year"
                      styles={{
                        container: (provided) => ({ ...provided, width: 170 }),
                        control: (provided) => ({
                          ...provided,
                          fontSize: '12px', 
                        }),
                        menu: (provided) => ({
                          ...provided,
                          fontSize: '12px', 
                        }),
                      }}
                    />
                    </Box>
                   </Box>
                   <Box
                        sx={{
                          height: "400px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {last12MonthsState && (
                          <TotalMonthlyCommissionGraph data={last12MonthsState} />
                        )}                      
                   </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>              
              <CommissionTable data={lenderLoanMissingCustomersState} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      {displayDashboard === displayDashboardValues.portfolio && (
        <PortfolioDashboard
          goBack={() => setDisplayDashboard(displayDashboardValues.main)}
        />
      )}
      {displayDashboard === displayDashboardValues.opportunity && (
        <OpportunityDashboard
          goBack={() => setDisplayDashboard(displayDashboardValues.main)}
        />
      )}

      <UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseExcelFileModal}
        onUpload={() =>
          fetchExcelFileStatusRequest({
            file,
            date,
            handleOpenConfirmation,
            uploadExcelFileRequest,
            trial,
            upfront,
            handleCloseExcelFileModal,
            handleCloseConfirmation,
          })
        }
        file={file}
        date={date}
        setFile={setFile}
        setDate={setDate}
        setUpfront={setUpfront}
        setTrial={setTrial}
        upfront={upfront}
        trial={trial}
      />

      <OverwriteExcelConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={() =>
          uploadExcelFileRequest({
            file,
            date,
            trial,
            upfront,
            handleCloseExcelFileModal,
            handleCloseConfirmation,
          })
        }
      />
    </main>
  );
};

export default Dashboards;
