import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import { Form, reduxForm, submit } from 'redux-form';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import TextInput from 'components/Styled/TextInput';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Checkbox, Input, Select, TextArea, ToggleButton } from 'components/Form';
import { DeleteButton } from 'views/Settings/Components/Shared';
import { ApplicationCardDisabled } from '../ApplicationCardDisabled';
import { useDispatch, useSelector } from 'react-redux';
import html from 'util/html';
import { baseUrl, emailService, smsService } from 'modules';
import SmsSentModal from './SmsSentModal';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { requestUpdateApplications } from 'modules/applications/actions';
import { requestPatchUser } from 'modules/clients/actions';

const senderValidation = (value, _, props) => {
  if (!value) return 'No sender selected';
  if (value === 'loanbase') return;
  const isSenderBroker = value === 'broker';
  const sender = (isSenderBroker) ? props.deal.broker : props.deal.processor;
  if (!sender) return isSenderBroker ? 'No broker is selected for this deal' : 'No processor is selected for this deal';
};

const templateValidation = (text, _, props) => {
  let isValid = true;
  if (!text) return 'Sms text cannot be empty';
  if (!props.deal.broker) {
    if (text.includes('{broker}') || text.includes('{brokerfirst}')) isValid = false;
  }
  if (!props.deal.processor) {
    if (text.includes('{processor}') || text.includes('{processorfirst}')) isValid = false;
  }
  if (!props.deal.lenderId) {
    if (text.includes('{lender}')) isValid = false;
  }
  return isValid ? null : 'The deal is missing needed {values}';
};

const contactsValidation = list => {
  if (!list) return;
  const numbers = list.split(',');
  for (const number of numbers)
    if (!phoneNumberValid(number)) 
      return 'One or more mobile numbers are invalid';
};

const phoneNumberValid = number => {
  let valid = true;
  number = number.replaceAll(' ', '');
  if (!number) return true;
  if (!number.match(/^[0-9,+]+$/)) valid = false;
  if (number.startsWith('04') && number.length !== 10) valid = false;
  return valid;
};

const includeClientValidation = (isIncluded, values, props) => {
  if (!isIncluded) {
    if (values.additionalNumbers) return;
    else return 'No recipients selected';
  }
  
  else return props.deal.client.phoneNumber ? null : 'Client is missing mobile number';
};

const SendSmsModalBase = ({ 
  modal, 
  sendSms, 
  goBack, 
  handleSubmit, 
  deal, 
  invalid, 
  initialize, 
  reset, 
  submitting, 
  page,
  ...props }) => {

  const dispatch = useDispatch();

  const stages = useSelector(state => state.applications.stages);
  const templateText = useSelector(state => state.form[props.form]?.values.smsTemplate);
  const templateName = useSelector(state => state.form[props.form]?.values.name);
  const lenders = useSelector(state => state.lenders.list);
  const syncErrors = useSelector(state => state.form[props.form].syncErrors);
  
  const changeTemplate = id => {
    let template = stages[id];
    if (!template) template = { id: -1, smsSender: 'loanbase', name: 'Custom SMS' };
    initialize({ ...template, includeClient: true });
    reset();
  };

  const pages = Object.values(stages)
    .filter(x => x.smsTemplate && x.smsTemplate.trim())
    .sort((a,b) => a.stageIndex - b.stageIndex)
    .reduce((obj, stage) => ({
      ...obj,
      [stage.page]: [ ...(obj[stage.page] ?? []), stage ]
    }), {});

  let templateOptions = [ 
    ...(pages['deals'] ?? []), 
    ...(pages['in-progress'] ?? []), 
    ...(pages['new-leads'] ?? []), 
    ...(pages['referrals'] ?? []), 
    ...(pages['qualifiers'] ?? []), 
    ...(pages['sms-template'] ?? [])
  ].map(stage => {
    let name;
    let stageName = capitalizeFirstLetter(stage.page);
    if (stage.page === 'new-leads') stageName = 'New Leads';
    if (stage.page === 'in-progress') stageName = 'In Progress';
    if (stage.page !== 'sms-template') name = `${stageName} - ${stage.name}`;
    else name = stage.name;
    return { name, value: stage.id };
  });

  templateOptions = [ { name: 'None', id: -1 }, ...templateOptions ];

  let brokerName = deal.broker ? `${deal.broker.firstName} ${deal.broker.lastName}` : null;
  let brokerFirstName = deal.broker ? deal.broker.firstName : null;
  let processorName = deal.processor ? `${deal.processor.firstName} ${deal.processor.lastName}` : null;
  let processorFirstName = deal.processor ? deal.processor.firstName : null;
  let clientName = deal.client ? `${deal.client.firstName}${deal.client.lastName ? ' ' + deal.client.lastName : ''}` : null;
  let clientFirstName = deal.client ? deal.client.firstName : null;
  let lenderName = lenders.find(x => x.id === deal.lenderId)?.name;
  let clientPhone = deal.client.phoneNumber;

  const replaceList = [
    ['{broker}', brokerName ],
    ['{brokerfirst}', brokerFirstName ],
    ['{processor}', processorName ],
    ['{processorfirst}', processorFirstName ],
    ['{client}', clientName ],
    ['{clientfirst}', clientFirstName ],
    ['{lender}', lenderName ],
    ['{brokeremail}', deal.broker?.email ],
    ['{processoremail}', deal.processor?.email ],
    ['{clientemail}', deal.client?.email ],
    ['{calendly}', deal.broker?.calendlyUrl ],
    ['’', '\''],
  ];

  const replaceListHtml = [
    ['\n', '<br/>'],
    ['{broker}', brokerName ?? warningHtml('(deal is missing a broker)')],
    ['{brokerfirst}', brokerFirstName ?? warningHtml('(deal is missing a broker)')],
    ['{processor}', processorName ?? warningHtml('(deal is missing a processor)') ],
    ['{processorfirst}', processorFirstName ?? warningHtml('(deal is missing a processor)')],
    ['{client}', clientName ],
    ['{clientfirst}', clientFirstName ],
    ['{lender}', lenderName ?? warningHtml('(deal is missing a lender)') ],
    ['{brokeremail}', deal.broker?.email ?? warningHtml('(deal is missing a broker)')],
    ['{processoremail}', deal.processor?.email ?? warningHtml('(deal is missing a processor)')],
    ['{clientemail}', deal.client?.email ],
    ['{calendly}', deal.broker ?  deal.broker.calendlyUrl ?? warningHtml('(broker is missing calendly url)') : warningHtml('(deal is missing a broker)')],
    ['’', '\''],
  ];

  let smsText = templateText ?? '';
  for (const [from, to] of replaceList) {
    smsText = smsText.replaceAll(from, to);
  }

  let smsTextHtml = templateText ?? '';
  for (const [from, to] of replaceListHtml) {
    smsTextHtml = smsTextHtml.replaceAll(from, to);
  }

  const senderOptions = [
    { name: `Assigned Broker (${brokerName ?? 'No broker'})`, value: 'broker' },
    { name: `Assigned Processor (${processorName ?? 'No processor'})`, value: 'processor' },
    { name: 'Secure Finance', value: 'loanbase' },
    { name: 'Secure Finance (Can receive)', value: 'can_receive' },
  ];
  
  const submit = async values => { 
    let to = [];
    if (values.includeClient && clientPhone) {
      to = [ clientPhone ];
    }
    if (values.additionalNumbers) {
      const additionalNumbers = values.additionalNumbers
        .replaceAll(' ', '')
        .split(',')
        .filter(x => !!x);
      to = [ ...to, ...additionalNumbers ];
    }

    let from;
    if (values.smsSender !== 'loanbase' && values.smsSender !== 'can_receive') {
      from = (values.smsSender === 'broker') ? deal.broker?.mobile : deal.processor?.mobile;
    } else {
      from = values.smsSender;
    }

    const smsProps = { 
      from, 
      to, 
      text: smsText, 
    };  

    const result = await smsService.create(smsProps);
    if (!result.error) {
      dispatch(requestPatchUser({
        data: {
          id: deal.client.id,
          lastSmsId: deal.id,
        }
      }));

      let sender = 'Secure Finance';
      if (values.smsSender === 'broker') {
        sender = `${brokerName}(${deal.broker.mobile})`;
      }
      if (values.smsSender === 'processor') {
        sender = `${processorName}(${deal.processor.mobile})`;
      }
      if (values.smsSender === 'can_receive') {
        sender = 'Secure Finance (Can receive)';
      }
      const dealUrl = `${baseUrl}/deals/view?id=${deal.id}`;
      const notifyBroker = values.smsNotifyBroker && brokerName && !!deal.broker?.email;
      const notifyProcessor =  values.smsNotifyProcessor && processorName && !!deal.processor?.email;

      const noteBody = html`
        <div><b>An SMS was sent to ${clientName} (${clientPhone}) from ${sender}:</b></div>
        <div><i>${smsTextHtml}</i></div>
        <br/>
        ${(notifyBroker || notifyProcessor) ? html`
          <div><b>Notifications were sent to:</b></div>
          ${notifyBroker ? html`<div>${brokerName} (${deal.broker.email})</div>` : ''}
          ${notifyProcessor ? html`<div>${processorName} (${deal.processor.email})</div>` : ''}
        ` : ''}
      `;

      dispatch(requestUpdateApplications({
        id: deal.id,
        note: { 
          body: noteBody,
          brokerId: 'system'
        }
      }));
      let emailRecipients = [];
      if (notifyBroker) emailRecipients.push(deal.broker.email);
      if (notifyProcessor) emailRecipients.push(deal.processor.email);

      if (notifyBroker || notifyProcessor) {
        const emailBody = html`
          <div>Hi ${brokerFirstName}</div>
          <br/>
          <div>${clientName} has recieved the following SMS (${templateName})</div>
          <br/>
          <div style="font-style: italic;">${smsTextHtml}</div>
          <br/>
          <div>
            The deal this pertains to is "${deal.dealDescription ?? 'description missing'}" with a value of ${toMoneyString(deal.loanAmount)}.
            Click here to view the deal <a href='${dealUrl}'>${dealUrl}</a>
          </div>
          <br/>
          <div>Thanks,</div>
          <br/>
          <div>Loan Base SMS Bot :)</div>
        `; 

        emailService.create({
          type: 'send-admin-email',
          to: emailRecipients,
          subject: `SMS to ${clientName} - ${templateName}`,
          body: emailBody,
        });
      }

      if (values.smsDebug) {
        const emailBody = html`
          <div>An automated SMS has been sent.</div>
          <br/>
          <div>Client Name: ${clientName}</div>
          <div>Deal Description: ${deal.dealDescription}</div>
          <div>Template Name: ${templateName}</div>
          <div>Sms Sender: ${sender}</div>
          <div>Sms Recipient(s): ${to.join(', ')}</div>
          <div>Notified Emails: ${emailRecipients.join(', ')}</div>
          <div>Message Content:</div>
          <div style="font-style: italic;">${smsTextHtml}</div>
          <div><a href='${dealUrl}'>${dealUrl}</a></div>
        `; 

        emailService.create({
          type: 'system-email',
          subject: `SMS sent (debug) - ${clientName} - ${templateName}`,
          body: emailBody,
        });
      }
    }

    sendSms({ formValues: values, result, smsHtml: smsTextHtml, deal, smsProps, sendSms, goBack });

  };
  debugger;
  return (
    <ModalBox style={{ width: '640px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Send SMS</SmallHeading>
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='265px'>
            <Label>Deal Confirmation</Label>
            <ApplicationCardDisabled data={deal} index={999999} isDisabled={true} page={page}/>
          </Column>
          <Column width='300px' margin='0 -20px 0 0'>
            <Row margin='0 0 9px 0'>
              <Label>SMS Template</Label>
              <Select width='300px' name='id' options={templateOptions} onChange={changeTemplate} />
            </Row>
            <Row margin='0 0 0 0'>
              <Label>SMS Sender</Label>
              <Select width='300px'name='smsSender' options={senderOptions} validate={senderValidation}/>
            </Row>
          </Column>
        </Row>
        <Row margin='0 0 5px 0'>
          <Label>SMS Message</Label>
          <SubLabel>{'Allowable strings: {broker}, {brokerfirst}, {client}, {clientfirst}, {processor}, {processorfirst}, {lender}, {brokeremail}, {processoremail}, {clientemail}, {calendly}'}</SubLabel>
          <TextArea name='smsTemplate' validate={templateValidation}/>
        </Row>
        <Row margin='0 0 20px 0'>
          <SmsPreview dangerouslySetInnerHTML={{ __html: smsTextHtml }} />
        </Row>
        <Row margin='0 0 5px 0'>
          <Column width='50%'>
            <Label style={{ marginBottom: '15px' }}>Recipient(s)</Label>
            <Checkbox style={{ marginLeft: '0px'}} name='includeClient' label={`${clientName} (${clientPhone ?? 'NO PHONE'})`} validate={includeClientValidation}/>
            <ErrorMessage>{props.submitFailed  ? syncErrors?.includeClient : ''}</ErrorMessage>
            
          </Column>
          <Column width='50%'>
            <Label>Additional receipient(s)</Label>
            <Input height='30px' name='additionalNumbers' validate={contactsValidation}/>
          </Column>
        </Row>
        <Row margin='0 0 10px 0'>
          <Column width='50%'>
            <Label style={{ marginBottom: '15px' }}>E-mail Notification</Label>
            <Checkbox style={{ marginLeft: '0px'}} label='Broker' name='smsNotifyBroker'/>
            <Checkbox style={{ marginLeft: '15px'}} label='Processor' name='smsNotifyProcessor'/>
          </Column>
          <PullRight style={{ position: 'relative', top: '20px'}}>
            <Label>
              <ToggleButton name='smsDebug' />
              Debug Mode
            </Label>
          </PullRight>
        </Row>
        
        <Row style={{ marginTop: '30px'}}> 
          <Column width='35%' margin='0 30% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={modal.close} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='35%'>
            <ButtonPrimary type='submit' width='100%' className={invalid ? 'disabled' : ''}><ButtonSpinner show={submitting}/>Send SMS</ButtonPrimary>
          </Column>
        </Row>
      </Form> 
    </ModalBox>
  );
};

const SendSmsModal = reduxForm({ form: 'send-sms'})(SendSmsModalBase);

export default SendSmsModal;

const ErrorMessage = styled.div`
  color: red;
  padding-top: 3px;
  font-size: 12px;
  height: 15px;
`;

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 26px !important;
  margin-top: -20px;
`;

const SubLabel = styled.div`
  color: #223241;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 12px;
`;

const SmsPreview = styled.div`
  border-radius: 8px;
  background: #F2F9FF;
  padding: 20px;
  font-size: 12px;
  line-height: 14px;
  min-height: 150px
`;

function capitalizeFirstLetter(string) {  
  return string[0].toUpperCase() +  string.slice(1); 
} 

function warningHtml(text) {
  return html`
    <span style='color: red;'>${text}</span>
  `;
}

function toMoneyString(amount) {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount*10)/10;
    return `$${rounded}`;
  }
    
  if (amount < 1000000) {
    const rounded = Math.round(amount/100)/10;
    return `$${rounded}k`;
  }

  const rounded = Math.round(amount/10000)/100;
  return `$${rounded}m`;
}