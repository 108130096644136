import { hostUrl } from "modules";

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const fetchDataRequest = async ({
  getDynamicURL,
  setLoading,
  setData,
  setError,
}) => {
  setLoading(true);
  try {
    const response = await fetch(getDynamicURL());
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    setData(result.data);
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

export const fetchExcelFileStatusRequest = async ({
  file,
  date,
  handleOpenConfirmation,
  uploadExcelFileRequest,
  trial,
  upfront,
  handleCloseExcelFileModal,
  handleCloseConfirmation,
}) => {
  const url = `${hostUrl}/active-client-data?date=${date}-1`;

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const result = await response.json();

    if (result.length > 0) {
      handleOpenConfirmation();
    } else {
      uploadExcelFileRequest({
        file,
        date,
        trial,
        upfront,
        handleCloseExcelFileModal,
        handleCloseConfirmation,
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const uploadExcelFileRequest = async ({
  file,
  date,
  trial,
  upfront,
  handleCloseExcelFileModal,
  handleCloseConfirmation,
}) => {
  const url = `${hostUrl}/active-client-data`;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("date", `${date}-1`);
  formData.append("totalTrail", trial);
  formData.append("totalUpfront", upfront);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const result = await response.json();
    console.log("Success:", result);
  } catch (error) {
    console.error("Error uploading file:", error);
  } finally {
    handleCloseExcelFileModal();
    handleCloseConfirmation();
  }
};
