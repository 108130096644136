import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { formatNumber } from "../utils";

const PieChartComponent = ({ dataKey, nameKey, breakdown, colors }) => {
  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontWeight: "bold", fontSize: "14px" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div
      style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
    >
      <div style={{ position: "relative", width: "300px", height: "300px" }}>
        <PieChart width={300} height={300}>
          <Pie
            data={breakdown}
            dataKey={dataKey}
            nameKey={nameKey}
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            labelLine={false}
            label={renderCustomLabel}
          >
            {breakdown?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `$ ${formatNumber(value)}`} />
        </PieChart>
      </div>

      <div style={{ marginTop: "-30px" }}>
        <ul
          style={{ listStyleType: "none", paddingLeft: 0, textAlign: "center" }}
        >
          {breakdown?.map((entry, index) => (
            <li
              key={index}
              style={{
                marginBottom: "10px",
                display: "inline-block",
                marginRight: "50px",
                marginLeft: "15px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: colors[index % colors.length],
                  marginRight: "8px",
                  borderRadius: "50%",
                  marginTop: "30px",
                }}
              ></span>
              {entry[nameKey]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PieChartComponent;
