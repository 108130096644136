import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const CapsuleChart = ({ data }) => {
  if (!data) return <div>No Data</div>;

  const maxValue =
    Math.max(
      data["Interest Only"]["Owner Occupy"],
      data["Interest Only"]["Investor"],
      data["Principal & Interest"]["Owner Occupy"],
      data["Principal & Interest"]["Investor"]
    ) + 2;

  const chartData = [
    {
      type: "IO",
      ownerOccupy: data["Interest Only"]["Owner Occupy"],
      investor: data["Interest Only"]["Investor"],
    },
    {
      type: "P&I",
      ownerOccupy: data["Principal & Interest"]["Owner Occupy"],
      investor: data["Principal & Interest"]["Investor"],
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={270}>
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{
          top: 20,
          right: 30,
          left: 40,
          bottom: 5,
        }}
        barCategoryGap={-10}
        barSize={25}
      >
        <CartesianGrid horizontal={false} vertical={true} />
        <XAxis
          type="number"
          tickLine={false}
          axisLine={false}
          tick={{ fontWeight: "bold" }}
          domain={[0, maxValue]}
          ticks={[...Array(maxValue + 1).keys()].slice(0, maxValue + 1)}
          label={{
            value: "Loans",
            position: "insideBottom",
            offset: -5,
            fontWeight: "bold",
            style: { textAnchor: "middle" },
          }}
        />
        <YAxis
          type="category"
          dataKey="type"
          tickLine={false}
          axisLine={false}
          tick={{ fontWeight: "bold" }}
          label={{
            value: "Type of Loan",
            position: "insideLeft",
            angle: -90,
            offset: -35,
            fontWeight: "bold",
            style: { textAnchor: "middle" },
          }}
        />
        <Tooltip cursor={{ fill: "transparent" }} />
        <Legend
          iconType="circle"
          wrapperStyle={{
            fontSize: "12px",
            
            position: "relative",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
          content={(props) => {
            const { payload } = props;
            return (
              <ul style={{ listStyle: "none", padding: 0, display: "flex" }}>
                {payload.map((entry, index) => (
                  <li
                    key={`item-${index}`}
                    style={{
                      marginRight: "65px",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: entry.color,
                        width: "10px",
                        height: "10px",
                        display: "inline-block",
                        marginRight: "5px",
                        borderRadius: "50%",
                      }}
                    ></span>
                    {entry.value}
                  </li>
                ))}
              </ul>
            );
          }}
        />
        <Bar
          dataKey="ownerOccupy"
          stackId="a"
          fill="#003f5c"
          radius={[10, 0, 0, 10]}
          name="Owner Occupied"
        >
          <LabelList dataKey="ownerOccupy" position="inside" fill="#fff" />
        </Bar>
        <Bar
          dataKey="investor"
          stackId="a"
          fill="#cccccc"
          radius={[0, 10, 10, 0]}
          name="Investor"
        >
          <LabelList dataKey="investor" position="inside" fill="#000" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CapsuleChart;
