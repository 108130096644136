import React, { Component, useEffect, useState } from 'react';

import { reduxForm, formValueSelector  } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonPrimary } from 'components/Styled/Button';
import Label from 'components/Styled/Label';
import Divider from 'components/Styled/Divider';

import { Input, Select, AddressInput, ToggleButton } from 'components/Form';
import { PullRight } from 'components/Styled/Layout';
import InputGroup from 'components/Common/InputGroup';
import { Row, Column } from '../ViewClient';
import ButtonSpinner from 'components/Styled/ButtonSpinner';
import { hostUrl, verificationService } from 'modules';

import { required } from 'util/validation';
import { requestPatchUser } from 'modules/clients/actions';
import moment from 'moment';
import styled from 'styled-components';

const phoneOptions = [
  { name: 'Mobile', value: 'Mobile' },
  { name: 'Landline', value: 'Landline' }
];

const superBalanceOptions = [
  { name: "Please select the super balance", value: null, disabled: true },
  { name: "< 150K", value: "<150K" },
  { name: "151K - 250K", value: "151K-250K" },
  { name: "251K - 500K", value: "251K-500K" },
  { name: "501K+", value: "501K+" },
];

let EditClientForm = ({ handleSubmit, pristine, submitting, invalid, user, form }) => {

  const dispatch = useDispatch();
  const [ sendingReferralEmail, setSendingReferralEmail ] = useState(false); 
  const [ clientproperty, setClientProperty ] = useState(undefined); 

  const brokerId = useSelector(state =>  state.clients.current?.proposalBroker?.id);
  const client = useSelector(state => state.clients.current);


  const isClientReferrer = client?.isClientReferrer;

  const sendReferralEmail = async () => {
    if (!isClientReferrer) return;
    setSendingReferralEmail(true);
    try {
      await sendEmail();
    } catch (err) {}
    setSendingReferralEmail(false);
  };

  const getUserProperties= async(userId)=>{
    const url = `${hostUrl}/property/${userId}`;
  
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json();
      return data; 
    } catch (error) {
      console.error('Error fetching user properties:', error);
      return { error: error.message }; 
    }
  }

  const sendEmail = () => {
    return new Promise( async (resolve, reject) => {
      try {
        await verificationService.create({
          type: 'refer-a-friend',
          email: client.email,
          brokerId,
        });
        resolve();

        dispatch(requestPatchUser({
          data: {
            id: client.id,
            meta: {
              referralInviteSentDate: moment().format(),
            }
          },
          params: {
            mergeMeta: true,
          },
        }));
      } catch(err) {
        reject();
      }
    });
  };

  const referralSentDate = client.meta?.referralInviteSentDate ? moment(client.meta.referralInviteSentDate) : null;

  const referrerTierOptions = [
    { name: 'Bronze', value: 'bronze' },
    { name: 'Silver', value: 'silver' },
    { name: 'Gold', value: 'gold' },
    { name: 'Traditional', value: 'traditional' },
  ];
  //debugger;

  useEffect(()=>{

   getUserProperties(client?.id)
     .then(user => {
       setClientProperty(user.propertyCount)
     })
     .catch(err => {
       console.error('Error:', err);
     });

  }, [client])


  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <input hidden autoComplete="off" />
      <Row>
        <Column width='50%'>
          <Label>First Name</Label>
          <Input name='client.firstName' validate={required}/>
        </Column>

        <Column width='50%'>
          <Label>Last Name</Label>
          <Input name='client.lastName'/>
        </Column>

        <Column width='50%'>
          <Label>Email</Label>
          <Input name='client.email'/>
        </Column>

        <Column width='50%'>
          <Label>Address</Label>
          <AddressInput width='340px' name='client.address'/>
        </Column>

        <Column width='50%'>
          <Label>Primary Phone</Label>
          <InputGroup>
            <Input type='phone' width='65%' name='client.phoneNumber'/>
            <Select width='35%' options={phoneOptions} name='client.phoneNumberType'/>
          </InputGroup>
        </Column>

        <Column width='50%'>
          <Label>Secondary Phone</Label>
          <InputGroup>
            <Input type='phone' width='65%' name='client.secondaryPhoneNumber'/>
            <Select width='35%' options={phoneOptions} name='client.secondaryPhoneNumberType'/>
          </InputGroup>
        </Column>

        <Column width='50%'>
          <ToggleButton title='Phone Number Confirmed' name='client.isMobileVerified' valueChecked={true} valueUnchecked={false}/>
        </Column>

        <Column width='50%'>
          <ToggleButton title='Phone Verification Required' name='client.mobileVerificationRequired' valueChecked={true} valueUnchecked={false}/>
        </Column>

        <Column width='50%'>
          <ToggleButton disabled={user.userType !== 'admin'} title='Is Professional Referrer' name='client.isProfessionalReferrer' valueChecked={true} valueUnchecked={false}/>
        </Column>
        <Column width='50%'>
          <ToggleButton title='Activate Refer-a-Friend' name='client.isClientReferrer' valueChecked={true} valueUnchecked={false}/>
        </Column>

        <Column width='50%'>
          <Label>Number of Properties</Label>
          <Input  displayValue={clientproperty} disabled={true}/>
        </Column>

        <Column width='50%'>
          <Label>Super Balance</Label>
          <Select
           width="100%"
           options={superBalanceOptions}
           name="client.superBalance"
          />
        </Column>

        {!!isClientReferrer && <>
          <Column width='50%'>
            <Label>Referral Tier</Label>
            <Select name='client.referralTier' options={referrerTierOptions}/>
          </Column>
        </>}

        
        
        
      </Row>
      <Row>
        <Row>
          <ButtonPrimary width='230px' className={isClientReferrer ? '' : 'disabled'} type='button' onClick={sendReferralEmail}><ButtonSpinner show={sendingReferralEmail}/>Send Refer-a-Friend Invite</ButtonPrimary>
          {referralSentDate &&
            <BlueInfoText>
              Sent at {referralSentDate.format('HH:mm')} on {referralSentDate.format('DD/MM/YYYY')}
            </BlueInfoText>
          }

          <PullRight>
            <ButtonPrimary disabled={invalid || pristine} className={(invalid || pristine) && 'disabled'} type='submit' width='100px' margin='0 80px 0 20px'><ButtonSpinner show={submitting}/>Save</ButtonPrimary>
          </PullRight>
        </Row>
        <Column width='100%'>
          <Divider width='100%' />
        </Column>
      </Row>
    </form>
  );
};

const BlueInfoText = styled.div`
  display: block;
  color: #4887f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 10px;
  animation: fadein 1s ease-in-out;
  animation-fill-mode: forwards;

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`; 

const stateToProps = ({ user }) => ({ user });

EditClientForm = reduxForm({
  //enableReinitialize: true,
})(EditClientForm);

EditClientForm = connect(stateToProps)(EditClientForm);

export default EditClientForm;