import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { Bold } from "shared/PDF/Shared";

const DonutChart = ({ data }) => {
  const CustomLegend = ({ payload }) => {
    return (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                backgroundColor: entry.payload.color,
                marginRight: 8,
                borderRadius: 4,
              }}
            ></div>
            <span style={{ color: entry.payload.color,fontSize: "12px" }}>
              {entry.payload.name} - 
              <span style={{ fontSize: "13px", fontWeight: 800 }}>
                {`$ ${(entry.payload.rValue / 1000000).toLocaleString()}M`}
              </span>
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={80}
          outerRadius={110}
          fill="#8884d8"
          paddingAngle={5}
          fontSize= "14px"
          label={({ value }) => `${value}%`}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name, props) => 
  `$${props.payload.rValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
} />

        <Legend
          layout="vertical"
          align="left"
          verticalAlign="middle"
          content={CustomLegend}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
