import React from "react";
import { styles } from "../dashboardConstants";

export const CustomInput = React.forwardRef(({ value, onClick,id }, ref) => (
  <button style={styles.datePickerCustomInput} onClick={onClick} ref={ref} id={id}>
    {value}
  </button>
));


