import { hostUrl } from "modules";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./PortfolioDashboard.css";
import LendersChart from "./LendersChart/LendersChart";
import { formatNumber } from "./utils.js";
import PieChartComponent from "./PieChartComponent/PieChartComponent";
import LollipopChart from "./LollipopChart/LollipopChart";
import CapsuleChart from "./CapsuleChart/CapsuleChart";
import {  Typography } from "@mui/material";

const PortfolioDashboard = ({ goBack }) => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 3,
    currentDate.getDate()
  );

  const [data, setData] = useState({
    loanInterestType: null,
    loanType: null,
    clientLoan: null,
    loanValueDistribution: null,
    repaymentLoanTypeDistribution: null,
    loanDetails: null,
    lenders: null,
    clientPropertyDistribution: null, // New data field
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(currentDate);

  const fetchData = async (fromDate, toDate) => {
    setLoading(true);
    setError(null);

    try {
      const requests = [
        fetch(`${hostUrl}/portfolio-dashboard/loan-interest-type?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/loan-type?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/client-loan?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/loan-value-distribution?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/repayment-loan-type-distribution?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/loan-details?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/portfolio-dashboard/lenders?fromDate=${fromDate}&toDate=${toDate}`),
        fetch(`${hostUrl}/property/propertydist?fromDate=${fromDate}&toDate=${toDate}`), // New fetch for client property distribution
      ];

      const responses = await Promise.all(requests);
      const results = await Promise.all(responses.map((res) => res.json()));

      setData({
        loanInterestType: results[0],
        loanType: results[1],
        clientLoan: results[2],
        loanValueDistribution: results[3],
        repaymentLoanTypeDistribution: results[4],
        loanDetails: results[5],
        lenders: results[6],
        clientPropertyDistribution: results[7], // Store new data
      });
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const colors = ["#284867", "#4B6DAB"];
  const colors2 = ["#F66D66", "#94B2C0"];

  useEffect(() => {
    const start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];
    fetchData(start, end);
  }, []);

  const handleLoadData = () => {
    const start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];
    fetchData(start, end);
  };

  return (
    <div>
      <div className="header">
        <div className="titleAndDateControls">
          <div className="datePickerContainer">
            <label htmlFor="startDate" className="dateLabel">From:</label>
            <DatePicker
              id="startDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<CustomInput />}
            />
          </div>
          <div className="datePickerContainer">
            <label htmlFor="endDate" className="dateLabel">To:</label>
            <DatePicker
              id="endDate"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              minDate={startDate}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<CustomInput />}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button onClick={handleLoadData} className="loadButton">
              {loading ? "Loading..." : "Load"}
            </button>
            <div style={{ marginLeft: '250px' }}>
            <Typography variant="h5" >Portfolio Dashboard</Typography>
            </div>
          </div>
        </div>

        <div className="controls">
          <button className="loadButton" onClick={goBack}>Dashboard</button>
        </div>
      </div>

      {loading && <div className="loading">Loading...</div>}

      {!loading && (
        <div className="contentContainer">
          <div className="contentGrid">
            <div className="columnContainer">
              <div className="contentBox smallBox">
                <span className="title">Total Loan Amount:</span>
                <span className="text">${formatNumber(data?.loanDetails?.totalLoanAmount)}</span>
              </div>
              <div className="contentBox smallBox">
                <span className="title">Total Clients:</span>
                <div className="circleContainer">
                  <span className="textCircle">{data?.loanDetails?.totalDistinctUsers}</span>
                </div>
              </div>
            </div>

            <div className="contentBox largeBox">
              <span className="lendersTitle">Group by Lender</span>
              <div className="chartContainer">
                <LendersChart data={data.lenders} />
              </div>
            </div>
          </div>
          <div className="chartsContainer">
            <div className="chartContainerCommon">
              <span className="chartTitle">Fixed & Variable Loans</span>
              <PieChartComponent
                dataKey="totalLoanAmount"
                nameKey="loanInterestType"
                breakdown={data?.loanInterestType?.interestTypeBreakdown}
                colors={colors}
              />
            </div>
            <div className="chartContainerCommon">
              <span className="chartTitle">Investors or Owner Occupiers</span>
              <PieChartComponent
                dataKey="totalLoanAmount"
                nameKey="loanType"
                breakdown={data?.loanType?.loanTypeBreakdown}
                colors={colors2}
              />
            </div>
          </div>

          <div className="chartsContainer">
            <div className="chartContainerCommon">
              <span className="chartTitle">Client Properties Portfolio Distribution</span>
              <LollipopChart
                data={data.clientPropertyDistribution} // Using the new data
                yAxisLabel="No of Properties"
                xAxisLabel="No of Clients"
                reverseYAxis={true}
              />
            </div>
            <div className="chartContainerCommon">
              <span className="chartTitle">Total Loan Value</span>
              <LollipopChart
                data={data.loanValueDistribution}
                yAxisLabel="Loan Value"
                xAxisLabel="Loans"
                reverseYAxis={false}
              />
            </div>
          </div>
          <div className="chartsContainer">
            <div className="chartContainerCommon">
              <span className="chartTitle">
                Principal & Interest and Interest only Loans
              </span>
              <CapsuleChart data={data.repaymentLoanTypeDistribution} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioDashboard;

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="datePickerCustomInput" onClick={onClick} ref={ref}>
    {value}
  </button>
));
