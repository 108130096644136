import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { format, parseISO, addDays } from "date-fns";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label">{label}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const calculateMaxValue = (data) => {
  const maxValue = data.reduce((max, day) => {
    const total = Object.values(day).reduce((sum, item) => {
      if (typeof item === "number") {
        return sum + item;
      }
      return sum;
    }, 0);
    return Math.max(max, total);
  }, 0);

  return Math.floor(maxValue * 1.1);
};

const filterData = (data) => {
  return data
    .map((day) => {
      const {
        conditionalApproved,
        unconditionalApproved,
        loanSettled,
        ...rest
      } = day;

      const sumLoans = (arr) =>
        arr?.reduce((sum, obj) => sum + obj.loanAmount, 0) || null;

      return {
        ...rest,
        conditionalApproved: sumLoans(conditionalApproved),
        unconditionalApproved: sumLoans(unconditionalApproved),
        loanSettled: sumLoans(loanSettled),
      };
    })
    .filter(
      (day) =>
        day.conditionalApproved || day.unconditionalApproved || day.loanSettled
    );
};

const SalesFigureChart = ({ data, startDate, endDate }) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  const dates = [];
  for (let d = start; d <= end; d = addDays(d, 1)) {
    dates.push(format(d, "dd-MMM-yy"));
  }

  const dataWithDates = data.map((item, index) => ({
    ...item,
    date: dates[index],
  }));

  const filteredData = filterData(dataWithDates);
  const maxValue = calculateMaxValue(filteredData);

  const formatValue = (value) => {
    if (value >= 1000) {
      const formattedValue = value / 1000;
      return Number.isInteger(formattedValue)
        ? `${formattedValue}k`
        : `${formattedValue.toFixed(1)}k`;
    }
    return value.toString();
  };

  return (
    <ResponsiveContainer width="100%" height={260}>
      <BarChart
        data={filteredData}
        barSize={18}
        margin={{
          top: 20,
          right: 30,
          left: 40,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12, fontWeight: "bold" }}
          angle={60}
          textAnchor="start"
          interval={0}
          height={80}
          label={{
            value: "Dates",
            fontWeight: "bold",
            position: "insideBottomRight",
            offset: -5,
          }}
        />
        <YAxis
          label={{
            value: "Amount",
            fontWeight: "bold",
            angle: -90,
            position: "insideLeft",
            offset: -35,
            style: { fontWeight: "bold" },
          }}
          domain={[0, maxValue]}
          tickFormatter={formatValue}
          tick={{ fontWeight: "bold" }}
        />
        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
        <Legend />
        {filteredData.some((day) => day.conditionalApproved) && (
          <Bar
            dataKey="conditionalApproved"
            name="Conditional Approved"
            stackId="a"
            fill="#8fd7cc"
            barSize={40}
          >
            <LabelList
              dataKey="conditionalApproved"
              position="left"
              formatter={formatValue}
            />
          </Bar>
        )}
        {filteredData.some((day) => day.unconditionalApproved) && (
          <Bar
            dataKey="unconditionalApproved"
            name="Unconditional Approved"
            stackId="a"
            fill="#FFCA28"
            barSize={40}
          >
            <LabelList
              dataKey="unconditionalApproved"
              position="left"
              formatter={formatValue}
            />
          </Bar>
        )}
        {filteredData.some((day) => day.loanSettled) && (
          <Bar
            dataKey="loanSettled"
            name="Loan Settled"
            stackId="a"
            fill="#3e72b8"
          >
            <LabelList
              dataKey="loanSettled"
              position="left"
              formatter={formatValue}
              barSize={40}
            />
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SalesFigureChart;
