import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { modalContext, ModalBox } from 'components/Modal/index.jsx';
import { Column, Row, PullLeft, PullRight } from 'components/Styled/Layout';
import { Form, reduxForm } from 'redux-form';
import Label from 'components/Styled/Label';
import SearchSelect from 'components/Common/SearchSelect';
import { ButtonPrimary, ButtonPlain } from 'components/Styled/Button';
import { Input, Select, TextArea, ToggleButton } from 'components/Form';
import { DeleteButton } from 'views/Settings/Components/Shared';
import { useDispatch, useSelector } from 'react-redux';
import { required } from 'util/validation';
import { removeStage } from 'modules/applications/actions';
import { EmailTextArea } from 'components/Form/EmailTextInput';

import imgX from 'img/button/close-small.svg';
import uriToBlob from 'shared/utils/uriToBlob';
import uploadFile from 'util/upload-file';
import { uploadUrl } from 'modules';
import ButtonSpinner from 'components/Styled/ButtonSpinner';

const $ = window.$;

const senderOptions = [
  { name: 'Secure Finance', value: 'loanbase' }, 
  { name: 'Assigned Broker', value: 'broker' },
  { name: 'Assigned Processor', value: 'processor' },
];

const EmailTemplateModalBase = ({ 
  modal, 
  onSubmit, 
  handleSubmit, 
  initialize, 
  reset, 
  initialValues,
  ...props 
}) => {
  
  const dispatch = useDispatch();
  const stages = useSelector(state => state.applications.stages);
  const templatePage = useSelector(state => state.form[props.form].values.page);
  const templateId = useSelector(state => state.form[props.form].values.id);
  const [ attachments, setAttachments ] = useState(initialValues.emailAttachments ?? []);
  const [ uploadingAttachment, setUploadingAttachment ] = useState(false);

  const selectFile = () => {
    $('#file-upload').click();
  };

  useEffect(() => {
    $('#file-upload').off();
    $('#file-upload').change(function(){
      const reader  = new FileReader();
      var file = this.files[0];
      // encode dataURI
      if (!file) return;
      setUploadingAttachment(true);
      reader.addEventListener('load', async () => {
        const blob = await uriToBlob(reader.result);
        const result = await uploadFile({
          file: blob,
          type: 'email-attachment',
          name: '',
        });
        setAttachments([ ...attachments, { filename: file.name, path: uploadUrl + '/' + result.uri }]);

        setUploadingAttachment(false);
      }, false);
  
      reader.readAsDataURL(file);
    });
  }, [attachments]);

  const deleteAttachment = useCallback(index => {
    console.log('Deleting attachment at index:', index);
    let attachmentsCopy = [ ...attachments ];
    attachmentsCopy.splice(index, 1);
    setAttachments(attachmentsCopy);
  }, [attachments]);

  const submit = values => {
    if (values.id === -1) {
      const maxId = Math.max(...Object.keys(stages));
      values.id = maxId + 1;
      values.isNew = true;
    }
    values.emailAttachments = attachments;
    if (!values.emailSender) values.emailSender = 'loanbase';
    onSubmit(values);
    modal.close();
  };

  const pages = Object.values(stages)
    .sort((a,b) => a.stageIndex - b.stageIndex)
    .reduce((obj, stage) => ({
      ...obj,
      [stage.page]: [ ...(obj[stage.page] ?? []), stage ]
    }), {});

  let templateOptions = [ 
    ...(pages['deals'] ?? []), 
    ...(pages['in-progress'] ?? []), 
    ...(pages['new-leads'] ?? []), 
    ...(pages['qualifiers'] ?? []), 
    ...(pages['referrals'] ?? []), 
    ...(pages['email-template'] ?? [])
  ].map(stage => {
    let name;
    let stageName = capitalizeFirstLetter(stage.page);
    if (stage.page === 'new-leads') stageName = 'New Leads';
    if (stage.page === 'in-progress') stageName = 'In Progress';
    if (stage.page !== 'email-template') name = `${stageName} - ${stage.name}`;
    else name = stage.name;
    return { name, value: stage.id };
  });

  templateOptions = [ { name: 'Add new template', value: -1 }, ...templateOptions ];

  const changeTemplate = id => {
    const template = id === -1 ? { id: -1, page: 'email-template' } : stages[id];
    console.log(template);
    setAttachments(template.emailAttachments ?? []);
    initialize(template);
    reset();
  };
 
  const deleteTemplate = () => {
    dispatch(removeStage(templateId));
    initialize({ id: -1, page: 'email-template' });
    reset();
  };  

  return (
    <ModalBox style={{ width: '640px', paddingBottom: '20px' }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          <SmallHeading>Stage E-mail Automation</SmallHeading>
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='320px'>
            <Label>Please select a template</Label>
            <Select width='300px' name='id' options={templateOptions} onChange={changeTemplate}/>
          </Column>
          {templatePage === 'email-template' && templateId !== -1 &&
            <Column width='100px'>
              <DeleteButton style={{ position: 'relative', top: '22px'}} onDelete={deleteTemplate}/>
            </Column>
          }
        </Row>
        {templatePage === 'email-template' &&
          <Row margin='0 0 20px 0'>
            <Label>Template Name</Label> 
            <Input width='300px' name='name' validate={required} />
          </Row>
        } 
        <Row margin='0 0 20px 0'>
          <Label>E-mail Sender</Label>
          <Select width='300px' options={senderOptions} name='emailSender'/>
        </Row>
        <Row margin='0 0 20px 0'>
          <Label>E-mail Subject</Label>
          <SubLabel>{'Allowable strings: {broker}, {brokerfirst}, {brokertitle}, {client}, {clientfirst}, {clientlast}, {processor}, {processorfirst}, {lender}'}</SubLabel>
          <Input width='100%' name='emailSubject' validate={required} />
        </Row>
        <Row margin='0 0 -30px 0'>
          <Label>E-mail Message</Label>
          <SubLabel>{'Allowable strings: {broker}, {brokerfirst}, {brokertitle}, {client}, {clientfirst}, {clientlast}, {processor}, {processorfirst}, {lender}, {brokeremail}, {processoremail}, {clientemail}, {calendly}, {referafriend}, {proposal}'}</SubLabel>
          <EmailTextArea name='emailTemplate'/>
        </Row>
        <Row margin='0 0 20px 0'>
          <ButtonPrimary margin='0 10px 0 0' height='30px' type='button' onClick={uploadingAttachment ? ()=>{} : selectFile}><ButtonSpinner show={uploadingAttachment}/>Attach a file...</ButtonPrimary>
          <input id='file-upload' type='file' name='upload' style={{display: 'none'}}/>
          {attachments.map((file, index) => 
            <Attachment name={file.filename} onDelete={() => deleteAttachment(index)} key={index}/>
          )}
        </Row>
        <Row margin='0 0 3px 0'>
          <Column width='50%'>
            <Label>
              <ToggleButton name='emailEnabled' valueChecked={1} valueUnchecked={0}/>  
              E-mail Automation Enabled
            </Label>
          </Column>
          <Column width='50%'>
            <Label>
              <ToggleButton name='emailDebug' valueChecked={1} valueUnchecked={0}/>
              Debug Mode
            </Label>
          </Column>
        </Row>
        <Row margin='0 0 20px 0'>
          <Column width='50%'>
            <Label>
              <ToggleButton name='emailNotifyBroker' valueChecked={1} valueUnchecked={0}/>
              cc in Broker
            </Label>
          </Column>
          <Column width='50%'>
            <Label>
              <ToggleButton name='emailNotifyProcessor' valueChecked={1} valueUnchecked={0}/>
              cc in Processor
            </Label>
          </Column>
          <Column width='50%'>
            <Label>
              <ToggleButton name='emailNotifyAdmin' valueChecked={1} valueUnchecked={0}/>
              cc in Admin
            </Label>
          </Column>
        </Row>
        <Row style={{ marginTop: '20px'}}> 
          <Column width='45%' margin='0 10% 0 0'>
            <ButtonPlain style={{ paddingBottom: '0'}} type='button' onClick={modal.close} width='100%'>Cancel</ButtonPlain>
          </Column>
          <Column width='45%'>
            <ButtonPrimary type='submit' width='100%'>Save</ButtonPrimary>
          </Column>
        </Row>
      </Form> 
    </ModalBox>
  );
};

const EmailTemplateModal = reduxForm({ form: 'email-template'})(EmailTemplateModalBase);

export default EmailTemplateModal;

const AttachmentPill = styled.span`
  position: relative;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  border-radius: 8.5px;
  background: #d8dce7;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 30px 0 10px;
`;

const AttachmentDelete = styled.span`
  position: absolute;
  right: 0px;
  border-left: 1px solid #c0c3cb;
  height: 25px;
  width: 20px;
  cursor: pointer;

  img {
    margin-left: 4px;
  }
`;

const Attachment = ({ name, onDelete }) => {
  return <AttachmentPill>
    {name}
    <AttachmentDelete onClick={onDelete}><img src={imgX}/></AttachmentDelete>
  </AttachmentPill>;
};

const SmallHeading = styled.h2`
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 26px !important;
  margin-top: -20px;
`;

const SubLabel = styled.div`
  color: #223241;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 12px;
`;

function capitalizeFirstLetter(string) {  
  return string[0].toUpperCase() +  string.slice(1); 
} 